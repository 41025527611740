<template>
  <div>
    <client :visible="visible" @edit="edit" :data="data" ref="refClient" @open_drawer_request="open_drawer_request" @close="close"/>
    <client-edit :visible="visible_edit" :data="data" ref="refClientEdit" @update_client="update_client" @close="close"/>
  </div>
</template>

<script>
import ClientEdit from "@/pages/AddClient/Client/ClientEdit";
import Client from "@/pages/AddClient/Client/Client";
import {EventBus} from "@/configs/eventBus";
import {mapActions} from "vuex";
export default {
  name: "ClientShowDrawer",
  components:{
    ClientEdit,
    Client
  },
  data(){
    return {
      data: null,
      visible_edit: false,
      visible: false,
    }
  },
  async mounted() {
    EventBus.$on('event_client_show_drawer', this.transition_main_request);
    await this.$nextTick();
    await this.mounted_func(false)
  },
  methods:{
    async transition_main_request(data_open, edit){
      console.log('data_open', data_open)
      try {
        await this.$router.push({query:{...this.$route.query, client_show_drawer: data_open.uid}})
      }catch (e) {

      }
      this.visible = false
      this.visible_edit = false
      await this.mounted_func(edit)
    },
    ...mapActions({
      PROFILES_CLIENT_OBJ:'customer_request/PROFILES_CLIENT_OBJ',
    }),
    async mounted_func(edit=false){
      // Получаем текущий URL
      const url = new URL(window.location.href);

      // Создаем объект URLSearchParams из параметров URL
      const params = new URLSearchParams(url.search);

      // Получаем значение параметра customer_request
      const client_show_drawer = params.get('client_show_drawer');
      if (client_show_drawer){
        await this.PROFILES_CLIENT_OBJ({client_show_drawer: client_show_drawer}).then(async (res)=>{
          this.data = {
            data:res
          }
          if (edit){
            await this.edit()
          }else{
            this.visible = true
            this.visible_edit = false
          }
        })
        this.get_history_change(client_show_drawer).then((res)=>{
          let clone = JSON.parse(JSON.stringify(this.data))
          clone['history_change'] = res
          this.data = clone
        })
      }


    },
    async edit(){
      this.visible = await false;
      this.visible_edit = await true;

      await this.$nextTick(); // Подождем, пока DOM обновится

      // Теперь ссылки на компоненты должны быть доступны

      if (this.$refs.refClientEdit.$refs.refFormEdit) {
        this.$refs.refClientEdit.$refs.refFormEdit.clientData = {
          uid: this.data.data.uid,
          first_name: this.data.data.first_name,
          last_name: this.data.data.last_name,
          middle_name: this.data.data.middle_name,
          // number: this.data.data.number,
          iin: this.data.data.iin,
          // address: this.data.data.address,
          email: this.data.data.email,
          notes: this.data.data.notes,
        };
        this.data.data.phone_numbers.forEach((item, index) => {
          this.$refs.refClientEdit.$refs.refFormEdit.clientData[`number_${index}`] = item.number
          this.$refs.refClientEdit.$refs.refFormEdit.clientData[`number_${index}_uid`] = item.uid
        })
      } else {
        console.error('Reference to refFormEdit is still undefined.');
      }
      console.log('this.$refs.refFormEdit', this.$refs.refClientEdit.$refs.refFormEdit)
    },
    update_client(val){
      this.close()
    },
    close(){
      let query = Object.assign({}, this.$route.query);
      delete query['client_show_drawer'];
      this.$router.push({ query });
      this.visible = false
      this.visible_edit = false
    },
    open_drawer_request(data, edit = false){
      this.$emit('open_drawer_request', data, edit)
    }
  }

}
</script>

<style scoped>

</style>
