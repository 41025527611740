<template xmlns="http://www.w3.org/1999/html">
  <ValidationObserver v-slot="{ validate, invalid }" ref="refValidationObserver">

    <div class="row">
        <div class="col-md-4 col-sm-12 mb-2">
          <span class="w-1/2 mb-2">
            {{ $t(first_name.model)}}:
            <span class="text-red-500" v-if="isRequired(first_name)">*</span>
          </span>
          <div>
            <ValidationProvider :rules="first_name.validate" v-slot="{ errors }" :name="'name'" class="w-full" >
              <a-input
                  :disabled="first_name.read_only === true"
                  v-if="first_name.mask"
                  v-mask="first_name.mask"
                  :name="first_name.model"
                  :placeholder="first_name.placeholder ? $t(first_name.placeholder) : $t(first_name.model)"
                  v-model="clientData[first_name.model]"/>
              <a-input
                  v-else
                  :disabled="first_name.read_only === true"
                  :name="first_name.model"
                  :placeholder="first_name.placeholder ? $t(first_name.placeholder) : $t(first_name.model)"
                  v-model="clientData[first_name.model]"/>
              <div v-if="errors.length>0" style="line-height: normal;">
                <span class="text-red-500">
                  {{ errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 mb-2">
          <span class="w-1/2 mb-2">
            {{ $t(last_name.model)}}:
            <span class="text-red-500" v-if="isRequired(last_name)">*</span>
          </span>
          <div>
            <ValidationProvider :rules="last_name.validate" v-slot="{ errors }" :name="'name'" class="w-full" >
              <a-input
                  :disabled="last_name.read_only === true"
                  v-if="last_name.mask"
                  v-mask="last_name.mask"
                  :name="last_name.model"
                  :placeholder="last_name.placeholder ? $t(last_name.placeholder) : $t(last_name.model)"
                  v-model="clientData[last_name.model]"/>
              <a-input
                  v-else
                  :disabled="last_name.read_only === true"
                  :name="last_name.model"
                  :placeholder="last_name.placeholder ? $t(last_name.placeholder) : $t(last_name.model)"
                  v-model="clientData[last_name.model]"/>
              <div v-if="errors.length>0" style="line-height: normal;">
                <span class="text-red-500">
                  {{ errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 mb-2">
          <span class="w-1/2 mb-2">
            {{ $t(middle_name.model)}}:
            <span class="text-red-500" v-if="isRequired(middle_name)">*</span>
          </span>
          <div>
            <ValidationProvider :rules="middle_name.validate" v-slot="{ errors }" :name="'name'" class="w-full" >
              <a-input
                  :disabled="middle_name.read_only === true"
                  v-if="middle_name.mask"
                  v-mask="middle_name.mask"
                  :name="middle_name.model"
                  :placeholder="middle_name.placeholder ? $t(middle_name.placeholder) : $t(middle_name.model)"
                  v-model="clientData[middle_name.model]"/>
              <a-input
                  v-else
                  :disabled="middle_name.read_only === true"
                  :name="middle_name.model"
                  :placeholder="middle_name.placeholder ? $t(middle_name.placeholder) : $t(middle_name.model)"
                  v-model="clientData[middle_name.model]"/>
              <div v-if="errors.length>0" style="line-height: normal;">
                <span class="text-red-500">
                  {{ errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </div>
        </div>
<!--      <div class="col-md-4 col-sm-12 mb-2">-->
<!--          <span class="w-1/2 mb-2">-->
<!--            {{ $t('username')}}:-->
<!--            <span class="text-red-500">*</span>-->
<!--          </span>-->
<!--        <div>-->
<!--          <ValidationProvider :rules="'required|username_exists'" v-slot="{ errors }" :name="'name'" class="w-full" >-->
<!--            <a-input-->
<!--                :name="'username'"-->
<!--                :placeholder="$t('username')"-->
<!--                v-model="clientData['username']"/>-->
<!--            <div v-if="errors.length>0" style="line-height: normal;">-->
<!--                <span v-if="errors[0].message === 'username_has_already_been'">-->
<!--                  <span class="flex flex-row flex-wrap justify-start" style="line-height: normal;">-->
<!--                    <span class="text-red-500">-->
<!--                      {{ $t('login_do_not') }}-->
<!--                    </span>-->
<!--                  </span>-->
<!--                </span>-->
<!--                <span v-else class="text-red-500">-->
<!--                  {{ errors[0] }}-->
<!--                </span>-->
<!--            </div>-->
<!--          </ValidationProvider>-->
<!--          <div class="cursor-pointer" style="color: #0a58ca;" @click="click_generate_login">-->
<!--            {{$t('generate_login')}}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
        <div class="col-md-4 col-sm-12 mb-2">
            <span class="w-1/2 mb-2">
              {{ $t(roles.model)}}:
              <span class="text-red-500" v-if="isRequired(roles)">*</span>
            </span>
          <div>
            <ValidationProvider :rules="roles.validate" v-slot="{ errors }" :name="roles.model" class="w-full" >
              <ant-select
                  :mode="roles.mode"
                  :clear_on_change="roles.clear_on_change"
                  :client_data_params="roles.client_data_params"
                  :use_last_request_params_event="roles.use_last_request_params_event"
                  :repeat_request_event="roles.repeat_request_event"
                  :clientData="clientData"
                  :disabled="roles.read_only === true"
                  :use_router_replace="roles.use_router_replace"
                  :filter_field="roles.filter_field"
                  v-model="clientData[roles.model]"
                  @input="selectHandleChange"
                  :placeholder="roles.placeholder ? $t(roles.placeholder) : $t(roles.model)"
                  :end_point="roles.end_point"
                  :select_list_component="roles.select_list_component"
                  :showSearch="true"
                  :use_pagination="true"
                  :name="roles.model"/>
              <div v-if="errors.length>0" style="line-height: normal;">
                  <span class="text-red-500">
                    {{ errors[0] }}
                  </span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 mb-2">
          <span class="w-1/2 mb-2">
            {{ $t(number.model)}}:
            <span class="text-red-500" v-if="isRequired(number)">*</span>
          </span>
          <div>
            <ValidationProvider :rules="number.validate" v-slot="{ errors }" :name="'name'" class="w-full" >
              <a-input
                  :disabled="number.read_only === true"
                  v-if="number.mask"
                  v-mask="number.mask"
                  :name="number.model"
                  :placeholder="number.placeholder ? $t(number.placeholder) : $t(number.model)"
                  v-model="clientData[number.model]"/>
              <a-input
                  v-else
                  :disabled="number.read_only === true"
                  :name="number.model"
                  :placeholder="number.placeholder ? $t(number.placeholder) : $t(number.model)"
                  v-model="clientData[number.model]"/>
              <div v-if="errors.length>0" style="line-height: normal;">
                <span class="text-red-500">
                  {{ errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 mb-2">
          <span class="w-1/2 mb-2">
            {{ $t(email.model)}}:
            <span class="text-red-500" v-if="isRequired(email)">*</span>
          </span>
          <div>
            <ValidationProvider :rules="email.validate" v-slot="{ errors }" :name="'name'" class="w-full" >
              <a-input
                  :disabled="email.read_only === true"
                  v-if="email.mask"
                  v-mask="email.mask"
                  :name="email.model"
                  :placeholder="email.placeholder ? $t(email.placeholder) : $t(email.model)"
                  v-model="clientData[email.model]"/>
              <a-input
                  v-else
                  :disabled="email.read_only === true"
                  :name="email.model"
                  :placeholder="email.placeholder ? $t(email.placeholder) : $t(email.model)"
                  v-model="clientData[email.model]"/>
              <div v-if="errors.length>0" style="line-height: normal;">
                <span class="text-red-500">
                  {{ errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div>
          <a-button class="mb-2" @click="add_organization_request">{{$t('add_organization_request')}}</a-button>
          <div>
            <div>

              <div class="row mb-2" v-for="(ele, index) in clientData.organization_number" :key="index">

                <div class="col-5">
                  <span class="w-1/2 mb-2">
                    {{ $t(organization_request.model)}}:
                    <span class="text-red-500" v-if="isRequired(organization_request)">*</span>
                  </span>
                  <ValidationProvider :rules="organization_request.validate" v-slot="{ errors }" :name="organization_request.model" class="w-full" >
                    <ant-select
                        :mode="organization_request.mode"
                        :clear_on_change="organization_request.clear_on_change"
                        :client_data_params="organization_request.client_data_params"
                        :use_last_request_params_event="organization_request.use_last_request_params_event"
                        :repeat_request_event="organization_request.repeat_request_event"
                        :clientData="clientData"
                        :disabled="organization_request.read_only === true"
                        :use_router_replace="organization_request.use_router_replace"
                        :filter_field="organization_request.filter_field"
                        v-model="clientData.organization_number[index].organization"
                        @input="selectHandleChangeOrganization($event, index)"
                        :placeholder="organization_request.placeholder ? $t(organization_request.placeholder) : $t(organization_request.model)"
                        :end_point="organization_request.end_point"
                        :select_list_component="organization_request.select_list_component"
                        :showSearch="true"
                        :use_pagination="true"
                        :name="organization_request.model"/>
                    <div v-if="errors.length>0" style="line-height: normal;">
                      <span class="text-red-500">
                        {{ errors[0] }}
                      </span>
                    </div>
                  </ValidationProvider>
                </div>

                <div class="col-5">
                  <span class="w-1/2 mb-2">
                    {{ $t(number.model)}}:
                    <span class="text-red-500" v-if="isRequired(number)">*</span>
                  </span>
                  <div>
                    <ValidationProvider :rules="number.validate" v-slot="{ errors }" :name="'name'" class="w-full" >
                      <a-input
                          :disabled="number.read_only === true"
                          v-if="number.mask"
                          v-mask="number.mask"
                          :name="number.model"
                          :placeholder="number.placeholder ? $t(number.placeholder) : $t(number.model)"
                          v-model="clientData.organization_number[index].number"/>
                      <a-input
                          v-else
                          :disabled="number.read_only === true"
                          :name="number.model"
                          :placeholder="number.placeholder ? $t(number.placeholder) : $t(number.model)"
                          v-model="clientData.organization_number[index].number"/>
                      <div v-if="errors.length>0" style="line-height: normal;">
                        <span class="text-red-500">
                          {{ errors[0] }}
                        </span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-2">
                  <div class="flex items-end mb-1" style="height: 100%;">
                    <a-button style="color:red;" icon="delete" @click="delete_org_item(index)"/>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
    </div>

  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from '@/configs/vee-validate';
import axios from "@/configs/axios";
import AntSelect from "@/components/AntSelect/AntSelect";
import TreeSelect from "@/components/AntSelect/TreeSelect";
import { mask } from 'vue-the-mask'
import EditFiles from "@/crm_components/files/EditFiles";
import GoogleMap from "@/crm_components/GoogleMap/GoogleMap";
import MapMixin from "@/crm_components/GoogleMap/MapMixin";
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import DateStartEnd from "@/components/Forms/FormAddClient/DateStartEnd";
import CheckboxFormSelect from "@/components/Forms/FormAddClient/CompsFormSelect/CheckboxFormSelect";
import {EventBus} from "@/configs/eventBus";
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import {mapActions, mapGetters} from "vuex";
import ProfileSelectView from "@/components/AntSelect/ProfileSelectView.vue";
let timer
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "FormSelectCreateUsers",
  components:{
    Treeselect,
    CheckboxFormSelect,
    DateStartEnd,
    AntSelect,
    ValidationProvider,
    ValidationObserver,
    TreeSelect,
    EditFiles,
    GoogleMap,
    LMap,
    LTileLayer,
    LMarker,
  },
  mixins:[
      MapMixin
  ],
  directives: {
    mask
  },
  data() {
    return {
      categories: [],
      clientData: {
        organization_number:[]
      },
      initial_form: {},
      fileList: [],
      form: this.$form.createForm(this, { name: 'coordinated' }),
      headers:{
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).access,
      },
      hide_list:[],
      customIcon:L.icon({
        iconUrl: 'https://cdn-icons-png.flaticon.com/512/443/443025.png',
        iconSize: [30, 30],
      }),
      form_items_clone: this.formItems,
      control_model_name:{},
      zoom: 13,
      marker: [51.505, -0.09],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      autoCompleteResult:[],
      provider: new OpenStreetMapProvider({
        params: {
          'accept-language': 'ru',
          countrycodes: ['ru', 'kz'],
          addressdetails: 0,
          limit: 7,
        },
      }),
    };
  },
  props:{
    formItems:{
      type: Array,
      default: null
    },
    cols:{
      type: Number,
      default: 2
    },
    show_label:{
      type: Boolean,
      default: false
    },
    updated_event_bus:{
      type: String,
      default: ''
    }
  },
  mounted() {
    this.REQUEST_TYPES()
    this.loadCategories()
  },
  computed:{
    ...mapGetters({
      GET_REQUEST_TYPES:'user/GET_REQUEST_TYPES',
    }),
    action(){
      let base_url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/api_rm52/v37`;
      if (window.location.hostname === 'localhost') {
        base_url = 'http://127.0.0.1:8000/api_rm52/v37';
      }
      return base_url + '/common/file/';
    },
    source_request(){
      return {
        model: 'source_request',
        type:'select',
        end_point:'organizations/sources/',
        validate: 'required',
      }
    },
    organization_request(){
      return {
        model: 'organization_request',
        type:'select',
        label: this.$t('organization'),
        end_point:'organizations/organizations/',
        placeholder: this.$t('organization'),
        validate: 'required',
      }
    },
    number(){
      return {
        model: 'number',
        label: this.$t('number'),
        show_label: true,
        placeholder: '+7',
        mask:'+7##########'
      }
    },
    region_fk(){
      return {
        model: 'region_fk',
        type:'select',
        end_point:'organizations/regions/',
        validate: 'required',
        repeat_request_event:['organization_request', 'responsible_manager'],
      }
    },
    level_request(){
      return {
        model: 'level_request',
        type:'select',
        end_point:'organizations/levels/',
        validate: 'required',
      }
    },
    email(){
      return {
        model: 'email',
        show_label: true,
        validate: 'required|custom_email',
      }
    },
    roles(){
      return {
        model: 'roles',
        type:'select',
        end_point:'organizations/roles/',
        validate: 'required',
        mode:'multiple',
      }
    },
    request_type(){
      return {
        model: 'request_type',
        type:'select',
        end_point:'organizations/request_types/',
        validate: 'required',
      }
    },
    no_address_required(){
      return {
        model:'no_address_required',
        type:'checkbox',
        show_label: false,
        in_case:true,
        hide: ['map_requests'],
      }
    },
    map_requests(){
      return {
        model: 'map_requests',
        type:'map',
        show_label: false,
        hide_if: 'no_address_required',
        validate: 'required',
      }
    },
    first_name(){
      return {
        model: 'first_name',
        show_label: true,
        validate: 'required',
      }
    },
    last_name(){
      return {
        model: 'last_name',
        show_label: true,
        validate: 'required',
      }
    },
    middle_name(){
      return {
        model: 'middle_name',
        show_label: true,
      }
    },
    description(){
      return {
        model: 'description',
        type:'textarea',
        validate: 'required',
      }
    },
    health_threat(){
      return {
        model:'health_threat',
        type:'checkbox',
        show_label: false,
      }
    },
    responsible_manager(){
      return {
        model: 'responsible_manager',
        type:'select',
        end_point:'organizations/performer/',
        select_list_component:ProfileSelectView,
        client_data_params:['organization_request', 'region_fk'],
        use_last_request_params_event:true,
        clear_on_change:true,
      }
    },
    attachments(){
      return {
        model: 'attachments',
        type:'upload',
        read_only:false,
      }
    },
    parent(){
      return {
        model: 'parent',
        type:'select',
        end_point:'organizations/customer_select/',
      }
    },
    categories_of_requests(){
      return {
        model: 'categories_of_requests',
        validate: 'required',
      }
    },


  },
  methods: {
    ...mapActions({
      GENERATE_USERNAME:'client/GENERATE_USERNAME',
    }),
    click_generate_login(){
      if (!this.clientData.first_name || !this.clientData.last_name){
        this.$notification.error({
          message: this.$t('fill_in_the_first_and_last_name'),
        });
      }else{
        this.GENERATE_USERNAME({first_name:this.clientData.first_name, last_name:this.clientData.last_name})
            .then((res)=>{
              console.log(res)
              let clone = JSON.parse(JSON.stringify(this.clientData))
              clone.username = res.username
              this.clientData = clone
            })
      }
    },
    delete_org_item(index){
      let clone = JSON.parse(JSON.stringify(this.clientData))
      clone.organization_number.splice(index, 1);
      this.clientData=clone
    },
    add_organization_request(){
      let clone = JSON.parse(JSON.stringify(this.clientData))
      clone.organization_number.push({
        organization:undefined,
        number:undefined,
      })
      this.clientData = clone

    },
    loadCategories() {
      axios.get('/organizations/categories/')
          .then(response => {
            this.categories = response.data;
          })
          .catch(error => {
            console.error('Ошибка загрузки данных:', error);
          });
    },
    ...mapActions({
      REQUEST_TYPES:'user/REQUEST_TYPES',
    }),
    updateMarker(latLng) {
      console.log('latLng', latLng)
      // this.marker = latLng;
      // this.clientData['map_location'] = latLng;
    },
    async handleAdressChange(value) {
      console.log('value', value)
      if(value === '') {
        this.autoCompleteResult = []
        this.searchVisible = false
        this.deliveryPoint = {
          lat: null,
          lon: null,
          address: '',
          name: '',
        }
        this.$emit('markedPoint', this.deliveryPoint)
      }
      clearTimeout(timer)
      if(value.trim().length >= 3) {
        timer = setTimeout(() => {
          this.searchLoading = true
          try {
            this.provider.search({ query: value })
                .then((result)=> {
                  this.autoCompleteResult = result
                })
          } catch(e) {
            console.log(e)
          } finally {
            this.searchLoading = false
          }
        }, 700)
      }
    },
    click_add(item){
      let clone_form_items_clone = []
      this.form_items_clone.forEach((el)=>{
        if (el.model === item.model){
          if (item.model in this.control_model_name) {
            this.control_model_name[item.model] += 1
          } else {
            this.control_model_name[item.model] = 1
          }
          console.log('this.control_model_name', this.control_model_name)

          let sub_items_clone = []
          el.add_items.forEach((ele)=>{
            sub_items_clone.push({
              ...ele,
              model: `${ele.model}_${this.control_model_name[item.model]}`
            })
          })
          el.sub_items.push(sub_items_clone)
        }

        clone_form_items_clone.push(el)

      })
      console.log('clone_form_items_clone', clone_form_items_clone)
      this.form_items_clone = clone_form_items_clone
    },
    delete_sub_items(item, ele, key_ele){
      let clone_form_items_clone = []
      this.form_items_clone.forEach((el)=>{
        if (el.model === item.model){
          el.sub_items = el.sub_items.filter((ele_child)=>ele_child!==ele)
        }
        clone_form_items_clone.push(el)
      })
      console.log(item, ele, key_ele)
      let clone = {...this.clientData}
      ele.forEach((el)=>{
        delete clone[el.model]
      })
      this.clientData = clone
      this.form_items_clone = clone_form_items_clone

    },
    throw_off(){
      this.clientData = {}
    },
    clean_all_key(){
      let clone = {}
      Object.keys(this.clientData).forEach(key => {
        clone[key] = '';
      });
      this.clientData = clone
    },

    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
    },
    async customRequest({ file, onSuccess, onError, onProgress }) {
      const formData = new FormData();
      formData.append('upload', file);

      try {
        const response = await axios.post(this.action, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...(file.headers || {})
          },
          onUploadProgress: (event) => {
            onProgress({ percent: (event.loaded / event.total) * 100 });
          }
        });
        onSuccess(response.data);
      } catch (error) {
        onError(error);
      }
    },
    handleChange(info, item) {
      console.log('info', info)
      console.log('item', item)
      let clone_file_list = info.fileList.map((el)=>{
        console.log(el)
        if (el.response){
          return el.response.uid
        }
        return el.uid
      })
      this.clientData[item.model] = clone_file_list
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    selectHandleChange(val){
      this.clientData[val.name] = val.select
      this.updated_call()
    },
    selectHandleChangeOrganization(val, index){
      let clone = JSON.parse(JSON.stringify(this.clientData))
      clone.organization_number[index].organization = val.select
      this.clientData = clone
    },
    showLabel(item) {
      return item.show_label === undefined || item.show_label === true;
    },
    isRequired(item) {
      return item.validate && item.validate.includes('required');
    },
    number_already_exists(errors){
      this.$emit('number_already_exists', errors);
      console.log('errors', errors);
    },
    iin_already_exists(errors){
      this.$emit('iin_already_exists', errors);
      console.log('errors', errors);
    },
    async default_change(value, item){
      console.log('name', value)
      console.log('item', item)
      this.clientData[item.model] = await value
      console.log('this.clientData', this.clientData)
    },
    async change_map(name, address){
      console.log('name', name)
      console.log('address', address)
      console.log('this.clientData', this.clientData)
      let clone = {...this.clientData}
      clone[name] = await address
      clone[`${name}_value`] = await address.full_address
      this.clientData = clone
    },
    change_checkbox(e, item){
      console.log('item', item)
      console.log('e', e)

      let clone = {...this.clientData}
      clone[item.model] = e.target.checked;
      this.clientData = clone
      if (item.hide){
        if (item.hide.length>0){
          if (e.target.checked === item.in_case){
            item.hide.forEach((ele)=>{
              this.hide_list.push(ele)
            })
          }else{
            item.hide.forEach((ele)=>{
              this.hide_list = this.hide_list.filter(item=>item!==ele)
            })
          }
        }
      }
      this.updated_call()

    },
    updated_call(){
      console.log('updated_call')
      // EventBus.$emit(this.updated_event_bus);
    }
  }
}
</script>

<style lang="scss">
.form-select{
  .ant-form-item-control{
    line-height: 30px;
  }
}
.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #d9d9d900 !important;
}
.ant-upload{
  width: 100%;
}
</style>
