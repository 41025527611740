<!--
	This is the dashboard layout, used in dashboard, tables, billing and profile pages.
 -->

<template>
  <div>

    <!-- Dashboard Layout -->
    <a-layout class="layout-dashboard" id="layout-dashboard" :class="[navbarFixed ? 'navbar-fixed' : '', ! sidebarCollapsed ? 'has-sidebar' : '', layoutClass, sidebarMinimized ? 'sidebar-minimized' : '' ]">

      <!-- Main Sidebar -->
      <DashboardSidebar
          :sidebarMinimized="sidebarMinimized"
          :sidebarCollapsed="sidebarCollapsed"
          :sidebarColor="sidebarColor"
          :sidebarTheme="sidebarTheme"
          @toggleSidebar="toggleSidebar"
      ></DashboardSidebar>
      <!-- / Main Sidebar -->

      <!-- Layout Content -->
      <a-layout>

        <!-- Layout Header's Conditionally Fixed Wrapper -->
        <DashboardHeader
            :sidebarCollapsed="sidebarCollapsed"
            :navbarFixed="navbarFixed"
            @toggleSettingsDrawer="toggleSettingsDrawer"
            @toggleSidebar="toggleSidebar"
            @minimizeSidebar="minimizeSidebar"
        ></DashboardHeader>
        <!-- / Layout Header's Conditionally Fixed Wrapper -->

        <!-- Page Content -->
        <a-layout-content>
          <router-view :navbarFixed="navbarFixed" />
        </a-layout-content>
        <!-- / Page Content -->

      </a-layout>

    </a-layout>
    <!-- / Dashboard Layout -->

  </div>
</template>

<script>

import DashboardSidebar from '../components/Sidebars/DashboardSidebar' ;
import DashboardHeader from '../components/Headers/DashboardHeader' ;
import DashboardFooter from '../components/Footers/DashboardFooter' ;
import DashboardSettingsDrawer from '../components/Sidebars/DashboardSettingsDrawer' ;
import {mapActions} from "vuex";
import WebSocketTest from "@/pages/WebSocketTest.vue";

export default ({
  components: {
    DashboardSidebar,
    DashboardHeader,
    DashboardFooter,
    DashboardSettingsDrawer,
  },
  mixins:[
    WebSocketTest
  ],
  data() {
    return {

      // Sidebar collapsed status.
      sidebarCollapsed: false,

      // Sidebar minimized status.
      sidebarMinimized: false,

      // Main sidebar color.
      sidebarColor: "primary",

      // Main sidebar theme : light, white, dark.
      sidebarTheme: "light",

      // Header fixed status.
      navbarFixed: true,

      // Settings drawer visiblility status.
      showSettingsDrawer: false,

    }
  },
  async created(){
    await this.PROFILE_INFO().then(async (res_ele)=>{
      console.log('res_ele', res_ele)
      if (!res_ele.user.is_staff){
        await this.$router.push({name:'change_password'});
      }
    })
    // await this.GLOBAL_SETTINGS()
    await this.GLOBAL_GLOBAL_SETTINGS_GET()
    await this.LOAD_CATEGORIES()
    // await this.PAGES_FRONT()
  },
  methods: {
    ...mapActions({
      PROFILE_INFO:'user/PROFILE_INFO',
      LOAD_CATEGORIES:'user/LOAD_CATEGORIES',
      // GLOBAL_SETTINGS:'user/GLOBAL_SETTINGS',
      GLOBAL_GLOBAL_SETTINGS_GET:'config/GLOBAL_GLOBAL_SETTINGS_GET',
    }),

    // Toggle sidebar's collapsed status.
    toggleSidebar( value ) {
      this.sidebarCollapsed = value ;
    },

    // Toggle sidebar's minimized status.
    minimizeSidebar( value ) {
      this.sidebarMinimized = ! this.sidebarMinimized ;
    },

    // Toggle ettings drawer's visiblility status.
    toggleSettingsDrawer( value ) {
      this.showSettingsDrawer = value ;
    },

    // Toggle navbar's fixed status.
    toggleNavbarPosition( value ) {
      this.navbarFixed = value ;
    },

    // Change sidebar's theme.
    updateSidebarTheme( value ) {
      this.sidebarTheme = value ;
    },

    // Change sidebar's color.
    updateSidebarColor( value ) {
      this.sidebarColor = value ;
    },
    click_logo(){
      if (this.$route.name !== 'main_page'){
        this.$router.push({name: 'main_page'})
      }
    }
  },
  computed: {
    // Sets layout's element's class based on route's meta data.
    layoutClass() {
      return this.$route.meta.layoutClass ;
    }
  },
})

</script>
