import axios from '@/configs/axios';

const state = () => ({
    organization_all: [],
});

const getters = {
    GET_ORGANIZATION_ALL: s => s.organization_all,
};

const mutations = {
    SET_ORGANIZATION_ALL(state, value){
        state.organization_all = value
    },
};

const actions = {

    async CUSTOMER_REQUEST_OBJ({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('organizations/drawer_customer_request/',  {params:payload})
                .then(response => {
                    // commit('SET_ORGANIZATION_ALL', response.data);
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async PROFILES_CLIENT_OBJ({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('organizations/drawer_client/', {params: payload} )
                .then(response => {
                    // commit('SET_ORGANIZATION_ALL', response.data);
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async CUSTOMER_REQUEST_DELETE({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.delete('organizations/drawer_customer_request_delete/', {params: payload} )
                .then(response => {
                    // commit('SET_ORGANIZATION_ALL', response.data);
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
