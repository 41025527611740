<script>
import ProfileSelectView from "@/components/AntSelect/ProfileSelectView";
import PhoneNumber from "@/components/AntSelect/ItemViews/PhoneNumber";

export default {
  name: "FormSelectItems",
  data(){
    return{
      formItemsEdit: [
        {
          model: 'first_name',
          show_label: true,
          validate: 'required',
        },
        {
          model: 'last_name',
          show_label: true,
          validate: 'required',
        },
        {
          model: 'middle_name',
          show_label: true,
        },
        // {
        //   model: 'number',
        //   show_label: true,
        //   validate: 'required',
        //   placeholder: '+7',
        //   mask:'+7##########'
        // },
        {
          model: 'iin',
          show_label: true,
          validate: 'iin_length|iin_numeric',
        },
        {
          model: 'email',
          show_label: true,
          validate: 'custom_email',
        },
        {
          model: 'notes',
          show_label: true,
        }
      ],
      formItemsCreate: [
        {
          model: 'first_name',
          show_label: true,
          validate: 'required',
          col:4,
          vertically:true,
        },
        {
          model: 'last_name',
          show_label: true,
          validate: 'required',
          col:4,
          vertically:true,
        },
        {
          model: 'middle_name',
          show_label: true,
          col:4,
          vertically:true,
        },
        {
          model: 'number',
          show_label: true,
          validate: 'required|number_exists',
          placeholder: '+7',
          mask:'+7##########',
          col:4,
          vertically:true,
        },
        {
          model: 'iin',
          show_label: true,
          validate: 'iin_length|iin_numeric|iin_exists',
          mask:'############',
          col:4,
          vertically:true,
        },
        {
          model: 'email',
          show_label: true,
          validate: 'custom_email',
          col:4,
          vertically:true,
        }
      ],
      form_only_notes:[
        {
          model: 'notes',
          show_label: true,
          col:12,
          vertically:true,
          type:'textarea',
        }
      ],
      formHasAccount: [
        {
          model: 'client',
          type:'select',
          end_point:'organizations/select_client_drawer_exists/',
          validate: 'required',
          col:6,
          vertically:true,
        },
        {
          model: 'number',
          placeholder: '+7',
          mask:'+7##########',
          show_label: true,
          col:6,
          vertically:true,
        },
      ],
    }
  },
  computed(){

  }
}
</script>

<style scoped>

</style>
