<template>
  <div>
    <custom-drawer :visible="visible" ref="refCustomDrawerEdit" v-if="data" :key_delete_router="['phone_number', 'stab']" @close="close">
      <template slot="title">
        {{ $t('edit') }} {{data.data.full_name}}
      </template>
      <template slot="content">
        <div class="w-full">
          <div style="margin-top: 50px;" class="w-1/2">
            <FormSelect :formItems="form_items_edit" ref="refFormEdit" style="margin-top: 50px;" :cols="1"/>
          </div>
        </div>
      </template>
      <template slot="content_bottom">
        <a-button
            class="default-button mr-1"
            size="small"
            @click="handleSubmit(
                $refs.refFormEdit,
                'pages_add_client_list',
                'organizations/drawer_client_updates/',
                'update'
                )">
          Сохранить
        </a-button>
      </template>

    </custom-drawer>
  </div>
</template>

<script>
import FormSelectItems from "@/pages/AddClient/ClientMixins/FormSelectItems";
import formSaveMixin from "@/crm_components/form_save/FormSaveMixin";
import FormSelect from "@/components/Forms/FormAddClient/FormSelect";
import CustomDrawer from "@/crm_components/CustomDrawer/CustomDrawer";

export default {
  name: "ClientEdit",
  components:{
    FormSelect,
    CustomDrawer,
  },
  mixins:[
      FormSelectItems,
      formSaveMixin,

  ],
  props:{
    visible:{
      type:Boolean,
      default: false
    },
    data:{
      type: Object,
      default: {}
    }
  },
  computed:{
    form_items_edit(){
      let clone = JSON.parse(JSON.stringify(this.formItemsEdit))
      this.data.data.phone_numbers.forEach((item, index) => {
        clone.push({
          model: `number_${index}`,
          show_label: true,
          validate: 'required',
          placeholder: '+7',
          mask:'+7##########'
        })
      })
      return clone
    }
  },
  methods:{
    afterUpdate(val){
      this.$emit('update_client', val)
    },
    close(){
      this.$emit('close')
    },
  }
}
</script>

<style scoped>

</style>
