<template>
  <div>
    <custom-drawer :visible="visible" ref="refClientAndRequestCreate" :key_delete_router="[]" @close="close">
      <template slot="title">
        <div>
          {{ $t('add') }}
        </div>
      </template>
      <template slot="content">
        <div class="w-full" style="margin-top: 50px; margin-bottom: 200px;">
            <form-create-org-deal/>
        </div>
      </template>
      <template slot="content_bottom">
        <a-button
            class="default-button mr-1"
            size="small"
            @click="create">
          Сохранить
        </a-button>
      </template>
    </custom-drawer>
  </div>
</template>

<script>
import CustomDrawer from "@/crm_components/CustomDrawer/CustomDrawer";
import FormSelect from "@/components/Forms/FormAddClient/FormSelect";
import FormSelectItems from "@/pages/AddClient/ClientMixins/FormSelectItems";
import FormItemsUpdate from "@/pages/AddClient/RequestMixins/FormItemsUpdate";
import formSaveMixin from "@/crm_components/form_save/FormSaveMixin";
import CustomTabs from "@/crm_components/custom_tabs/CustomTabs";
import GoogleMap from "@/crm_components/GoogleMap/GoogleMap";
// import formOrg from "./Mixins/formOrg"
import {EventBus} from "@/configs/eventBus";
import FormCreateDeal from "@/pages/Leaf/pages/Deal/FormCreateDeal.vue";
import FormCreateOrgDeal from "@/pages/Leaf/pages/OrganizationRequestForTheDeal/FormCreateOrgDeal.vue";
export default {
  name: "DrawCreateOrgDeal",
  components:{
    FormCreateOrgDeal,
    FormCreateDeal,
    GoogleMap,
    CustomDrawer,
    FormSelect,
    CustomTabs,
  },
  props:{
    name_event_bus:{
      type:String,
      default: 'clients_event_bus'
    },
    title:{
      type:String,
      default: ''
    },
  },
  data(){
    return{
      visible:false,
      has_an_account:false,
      no_address_required:false,
      health_threat:false,
      add_request:false,
      // tabs:[
      //   {name:this.$t('requests'), key:'requests'},
      // ],
    }
  },
  mixins:[
      FormSelectItems,
      FormItemsUpdate,
      formSaveMixin,
      // formOrg
  ],
  async mounted() {
    EventBus.$on('open_create_org_deal', this.open_create_org_deal);
  },
  methods:{
    open_create_org_deal(){
      this.visible = true
    },
    async create(){
      // try {
      //   // this.$refs.refFormCreateOrg.clientData['regions'] = [this.$refs.refFormCreateOrg.clientData['region_fk']]
      //   await this.handleSubmit(
      //       this.$refs.refFormCreateOrg,
      //       this.name_event_bus,
      //       'organizations/drawer_organization_request_create/',
      //       'create',
      //       null
      //   )
      //   this.close()
      // }catch (e) {
      //   console.log('create_client_and_request', e)
      // }


    },
    onChange(e) {
      this.has_an_account = e.target.checked;
    },
    on_add_request(e) {
      this.add_request = e.target.checked;
    },
    onChangeNoAddressRequired(e){
      this.no_address_required = e
    },
    onChangeHealthThreat(e){
      this.health_threat = e
    },
    close(){
      this.visible = false
    },
    afterCreate(val){
      val.refForm.clientData = {}
      this.$emit('after_create', val)
    },
    afterUpdate(){
      this.$emit('after_update', val)
    },
  }
}
</script>

<style scoped>

</style>
