<template>
  <div>
    <div class="mb-4">
      {{data.description}}
    </div>
    <div v-if="data.parent">
      {{$t('main_request')}}:
      <span class="a-link cursor-pointer" @click="transition_main_request">
        {{data.parent.name}}
      </span>
    </div>
<!--    <div>-->
<!--      <card>-->
<!--        <AgGridTable-->
<!--            :list_keys_query_params="['parent']"-->
<!--            ref="refSubtasks"-->
<!--            updated_event_bus="updated_customer_request"-->
<!--            @open_drawer="open_drawer_request"-->
<!--            :column_defs="column_defs_create_request"-->
<!--            :end_point="'organizations/customer/'"-->
<!--            name_event_bus="customer_request_api_event_bus"/>-->
<!--      </card>-->
<!--    </div>-->
    <div v-if="data.map_requests">
      <form-select :cols="1" :show_label="true" :formItems="formItemsOnlyMap" ref="refFormUpdateRequestMap"/>
    </div>
    <div v-if="data.attachments.length > 0">
      <form-select :cols="1" :show_label="true" :formItems="formItemsFiles" ref="refFormUpdateRequestFiles"/>
    </div>
    <div class="w-full" >
      <div ref="tabularTable"></div>
    </div>


  </div>
</template>

<script>
import FormSelect from "@/components/Forms/FormAddClient/FormSelect";
import AgGridTable from "@/components/Forms/FormAddClient/AgGridTable.vue";
import ColumnDefsCreateRequest from "@/pages/AddClient/RequestMixins/ColumnDefsCreateRequest.vue";
import OpenDrawerRequestMixin from "@/pages/AddClient/RequestMixins/OpenDrawerRequestMixin.vue";
import DeleteMixin from "@/pages/AddClient/Client/DeleteMixin/DeleteMixin.vue";
import RequestFilterMixin from "@/pages/AddClient/RequestMixins/RequestFilterMixin.vue";
import OpenDrawerClientMixin from "@/pages/AddClient/ClientMixins/OpenDrawerClientMixin.vue";
import RoleMixin from "@/Mixins/RoleMixin.vue";
import Card from "@/crm_components/card/Card.vue";
import Tabulator from '@/components/CustomTabular/tabulator-master/dist/js/tabulator.js'
import {EventBus} from "@/configs/eventBus";
export default {
  name: "RequestInfo",
  components: {
    Card,
    AgGridTable,
    FormSelect
  },
  mixins:[
    ColumnDefsCreateRequest,
    OpenDrawerRequestMixin,
    DeleteMixin,
    RequestFilterMixin,
    OpenDrawerClientMixin,
    RoleMixin,
  ],
  props:{
    data:{
      type: Object,
      default: {}
    },
    visible:{
      type:Boolean,
      default: false
    },
  },
  watch:{
    visible(val){
      if (val){
        this.set_data()
      }
    }
  },
  async mounted() {
    await this.set_data()
  },
  methods:{
    clear_clien_data(){
      try {
        this.$refs.refFormUpdateRequestMap.throw_off()

      }catch (e) {

      }
      try {
        this.$refs.refFormUpdateRequestFiles.throw_off()

      }catch (e) {

      }

    },
    transition_main_request(){
      this.$emit('transition_main_request', this.data.parent)
    },
    async set_data(){
      try {
        await this.$nextTick();
        let clone_client_data = {}
        clone_client_data['map_requests'] = this.data.map_requests
        clone_client_data['map_requests_value'] = this.data.map_requests?this.data.map_requests.full_address:null
        if (this.data.map_requests){
          this.$refs.refFormUpdateRequestMap.markers = [{ lat: this.data.map_requests.lat, lng:this.data.map_requests.lng,}];
          this.$refs.refFormUpdateRequestMap.center = {
            lat: this.data.map_requests.lat,
            lng: this.data.map_requests.lng
          }
        }
        this.$refs.refFormUpdateRequestMap.clientData = clone_client_data


        clone_client_data = {}
        clone_client_data['attachments'] = this.data.attachments
        this.$refs.refFormUpdateRequestFiles.clientData = clone_client_data

        if (this.$refs.refFormUpdateRequestFiles.$refs.refUpload){
          this.$refs.refFormUpdateRequestFiles.$refs.refUpload[0].sFileList = this.data.attachments
        }
      }catch (e) {
        console.log('error', e)
      }

      console.log('this.data.children', this.data.children)
      if (this.data.children.length>0){
        this.tabulator = new Tabulator(this.$refs.tabularTable, {
          data: this.data.children, //link data to table
          columns: [
            {
              headerName: "#",
              title: "#",
              use:true,
              headerSort: false,
              field: "request_number_v2",
              minWidth: 100,
              maxWidth: 100,
            },
            {
              headerName: this.$t('name'),
              title: this.$t('name'),
              headerSort: false,
              use:true,
              field: "name",
              minWidth: 150,
              cellClass: ['a-link', 'cursor-pointer'],
              open_drawer:true,
              formatter:function(cell, formatterParams){
                var value = cell.getValue();
                return "<span class='a-link cursor-pointer'>" + value + "</span>";
              },
            },
            {
              headerName: "Дата",
              title: "Дата",
              headerSort: false,
              use:true,
              field: "created",
              minWidth: 150,
            },
            {
              headerName: this.$t('status'),
              title: this.$t('status'),
              headerSort: false,
              use:true,
              field: "status_request.name",
              minWidth: 150,
            },
            {
              headerName: this.$t('result'),
              title: this.$t('result'),
              headerSort: false,
              use:true,
              field: "result_request.name",
              minWidth: 150,
            },
          ], //define table columns
        });
        this.tabulator.on("rowClick", this.open_drawer);
      }

    },
    open_drawer(e, row){
      console.log('row', row)
      console.log('row.getData()', row.getData())
      EventBus.$emit('event_request_show_drawer', row.getData(), false)
    },
  },
  data(){
    return{
      formItemsOnlyMap:[
        {
          model: 'map_requests',
          type:'map',
          read_only:true,
          vertically:true
        },
      ],
      formItemsFiles:[
        {
          model: 'attachments',
          type:'upload',
          read_only:true,
          vertically:true
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
