import axios from '@/configs/axios';

const state = () => ({
    statuses: [],
});

const getters = {
    GET_STATUSES: s => s.statuses,
};

const mutations = {
    SET_STATUS(state, value){
        state.statuses = value
    },
    SET_CLEAR(state){
        state.statuses = []
    }

};

const actions = {
    async STATUS({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('organizations/pages_status_request_list/', {params:payload})
                .then(response => {
                    commit('SET_STATUS', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
