<template>
  <div>
    <custom-drawer :visible="visible" @close="close">
      <template slot="title">
        <div v-if="data">
          #{{ data.data.request_number_v2 }} {{ data.data.name }}
        </div>
      </template>
      <template slot="tabs">
        <custom-tabs :tabs="tabs" :visible="visible"/>
      </template>
      <template slot="content">
        <div class="flex w-full" style="margin-top: 100px;margin-bottom: 100px;">
          <div class="w-2/3">
            <div v-if="$route.query.stab === 'main'">
              <request-info ref="refRequestInfo" :data="data.data" :visible="visible" @transition_main_request="transition_main_request"/>
            </div>
            <div v-else-if="$route.query.stab === 'history_change'">
              <history-change :data="data.history_change"/>
            </div>
          </div>
          <div class="w-1/3">
            <div style="padding-left: 25px;">
              <card-v2 style="height: max-content;">
<!--                <description-info-request v-if="data" :data="data.data"/>-->
                <request-right-info v-if="data" :data="data.data" @go_client="go_client"/>
              </card-v2>
            </div>
          </div>
        </div>
      </template>
      <template slot="content_bottom" >
        <ActionsRequest @update_request="update_request" :data_request="data.data" :is_watch="false" @onEditClicked="edit" @onDeleteClicked="delete_request"/>
      </template>
    </custom-drawer>
  </div>
</template>

<script>
import CustomDrawer from "@/crm_components/CustomDrawer/CustomDrawer";
import ClientInfo from "@/pages/AddClient/Info/ClientInfo";
import CustomTabs from "@/crm_components/custom_tabs/CustomTabs";
import DescriptionInfoRequest from "@/pages/AddClient/Info/DescriptionInfoRequest";
import CardV2 from "@/crm_components/card_v2/CardV2";
import EditFiles from "@/crm_components/files/EditFiles";
import Tiptap from "@/components/Tiptap/Tiptap";
import ColorIcon from "@/crm_components/icons/ColorIcon";
import DeleteMixin from "@/pages/AddClient/Client/DeleteMixin/DeleteMixin";
import RequestInfo from "@/pages/AddClient/Info/RequestInfo";
import RequestRightInfo from "@/pages/AddClient/Info/RequestRightInfo";
import RoleMixin from "@/Mixins/RoleMixin";
import ActionsRequest from "../RequestMixins/ActionsRequest";
import HistoryChange from "@/crm_components/HistoryChange/HistoryChange.vue";
import {mapActions} from "vuex";
export default {
  name: "Request",
  components: {
    HistoryChange,
    RequestRightInfo,
    RequestInfo,
    Tiptap,
    EditFiles,
    DescriptionInfoRequest,
    CustomDrawer,
    ClientInfo,
    CustomTabs,
    CardV2,
    ColorIcon,
    ActionsRequest,
  },
  data(){
    return {
      tabs:[
        {name:this.$t('basic'), key:'main'},
        {name:this.$t('history_change'), key:'history_change'},
        // {name:this.$t('files'), key:'files'},
        // {name:this.$t('client'), key:'client'},
      ],
    }
  },
  props:{
    visible:{
      type:Boolean,
      default: false
    },
    data:{
      type: Object,
      default: {}
    }
  },
  mixins:[
    DeleteMixin,
    RoleMixin,
  ],
  methods:{
    ...mapActions({
      CUSTOMER_REQUEST_DELETE:'customer_request/CUSTOMER_REQUEST_DELETE',
    }),
    edit(){
      this.$emit('edit')
    },
    async delete_request(){
      // await this.CUSTOMER_REQUEST_DELETE({customer_request:this.data.data.uid})
      this.delete_method(
          'pages_customer_request_list',
          'organizations/drawer_customer_request_delete/',
          this.data.data
      )
    },
    close(){
      try {
        this.$refs.refRequestInfo.clear_clien_data()
      }catch (e) {
        console.log('e', e)
      }
      this.$emit('close')
    },
    go_client(val){
      this.$emit('go_client', val)
    },
    update_request(val){
      this.$emit('update_request', val)
    },
    transition_main_request(data_open){
      this.$emit('transition_main_request', data_open)
    },
  }
}
</script>

<style scoped>

</style>
