<template xmlns="http://www.w3.org/1999/html">
  <ValidationObserver v-slot="{ validate, invalid }" ref="refValidationObserver">

    <div class="row">

      <div class="col-md-4 col-sm-12">

        <div class="col-12 mb-2">
          <span class="w-1/2 mb-2">
            {{ $t(name_input.model)}}:
            <span class="text-red-500" v-if="isRequired(name_input)">*</span>
          </span>
          <div>
            <ValidationProvider :rules="'required'" v-slot="{ errors }" :name="'name'" class="w-full" >
              <a-input
                  :disabled="name_input.read_only === true"
                  v-if="name_input.mask"
                  v-mask="name_input.mask"
                  :name="name_input.model"
                  :placeholder="name_input.placeholder ? $t(name_input.placeholder) : $t(name_input.model)"
                  v-model="clientData[name_input.model]"/>
              <a-input
                  v-else
                  :disabled="name_input.read_only === true"
                  :name="name_input.model"
                  :placeholder="name_input.placeholder ? $t(name_input.placeholder) : $t(name_input.model)"
                  v-model="clientData[name_input.model]"/>
              <div v-if="errors.length>0" style="line-height: normal;">
                <span class="text-red-500">
                  {{ errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div class="col-12 mb-2">
          <span class="w-1/2 mb-2">
            {{ $t(name_short_input.model)}}:
            <span class="text-red-500" v-if="isRequired(name_short_input)">*</span>
          </span>
          <div>
            <ValidationProvider :rules="'required'" v-slot="{ errors }" :name="'name'" class="w-full" >
              <a-input
                  :disabled="name_short_input.read_only === true"
                  v-if="name_short_input.mask"
                  v-mask="name_short_input.mask"
                  :name="name_short_input.model"
                  :placeholder="name_short_input.placeholder ? $t(name_short_input.placeholder) : $t(name_short_input.model)"
                  v-model="clientData[name_short_input.model]"/>
              <a-input
                  v-else
                  :disabled="name_short_input.read_only === true"
                  :name="name_short_input.model"
                  :placeholder="name_short_input.placeholder ? $t(name_short_input.placeholder) : $t(name_short_input.model)"
                  v-model="clientData[name_short_input.model]"/>
              <div v-if="errors.length>0" style="line-height: normal;">
                <span class="text-red-500">
                  {{ errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div class="col-12 mb-2">
          <span class="w-1/2 mb-2">
            {{ $t(bin.model)}}:
            <span class="text-red-500" v-if="isRequired(bin)">*</span>
          </span>
          <div>
            <ValidationProvider :rules="'required'" v-slot="{ errors }" :name="'name'" class="w-full" >
              <a-input
                  :disabled="bin.read_only === true"
                  v-if="bin.mask"
                  v-mask="bin.mask"
                  :name="bin.model"
                  :placeholder="bin.placeholder ? $t(bin.placeholder) : $t(bin.model)"
                  v-model="clientData[bin.model]"/>
              <a-input
                  v-else
                  :disabled="bin.read_only === true"
                  :name="bin.model"
                  :placeholder="bin.placeholder ? $t(bin.placeholder) : $t(bin.model)"
                  v-model="clientData[bin.model]"/>
              <div v-if="errors.length>0" style="line-height: normal;">
                <span class="text-red-500">
                  {{ errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div class="col-12 mb-2">
          <span class="w-1/2 mb-2">
            {{ $t(address.model)}}:
            <span class="text-red-500" v-if="isRequired(address)">*</span>
          </span>
          <div>
            <ValidationProvider :rules="'required'" v-slot="{ errors }" :name="'name'" class="w-full" >
              <a-input
                  :disabled="address.read_only === true"
                  v-if="address.mask"
                  v-mask="address.mask"
                  :name="address.model"
                  :placeholder="address.placeholder ? $t(address.placeholder) : $t(address.model)"
                  v-model="clientData[address.model]"/>
              <a-input
                  v-else
                  :disabled="address.read_only === true"
                  :name="address.model"
                  :placeholder="address.placeholder ? $t(address.placeholder) : $t(address.model)"
                  v-model="clientData[address.model]"/>
              <div v-if="errors.length>0" style="line-height: normal;">
                <span class="text-red-500">
                  {{ errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </div>
        </div>

      </div>
    </div>

  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from '@/configs/vee-validate';
import axios from "@/configs/axios";
import AntSelect from "@/components/AntSelect/AntSelect";
import TreeSelect from "@/components/AntSelect/TreeSelect";
import { mask } from 'vue-the-mask'
import EditFiles from "@/crm_components/files/EditFiles";
import GoogleMap from "@/crm_components/GoogleMap/GoogleMap";
import MapMixin from "@/crm_components/GoogleMap/MapMixin";
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import DateStartEnd from "@/components/Forms/FormAddClient/DateStartEnd";
import CheckboxFormSelect from "@/components/Forms/FormAddClient/CompsFormSelect/CheckboxFormSelect";
import {EventBus} from "@/configs/eventBus";
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import {mapActions, mapGetters} from "vuex";
import ProfileSelectView from "@/components/AntSelect/ProfileSelectView.vue";
let timer
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "FormCreateOrgDeal",
  components:{
    Treeselect,
    CheckboxFormSelect,
    DateStartEnd,
    AntSelect,
    ValidationProvider,
    ValidationObserver,
    TreeSelect,
    EditFiles,
    GoogleMap,
    LMap,
    LTileLayer,
    LMarker,
  },
  mixins:[
      MapMixin
  ],
  directives: {
    mask
  },
  data() {
    return {
      categories: [],
      clientData: {},
      initial_form: {},
      fileList: [],
      form: this.$form.createForm(this, { name: 'coordinated' }),
      headers:{
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).access,
      },
      hide_list:[],
      customIcon:L.icon({
        iconUrl: 'https://cdn-icons-png.flaticon.com/512/443/443025.png',
        iconSize: [30, 30],
      }),
      form_items_clone: this.formItems,
      control_model_name:{},
      zoom: 13,
      marker: [51.505, -0.09],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      autoCompleteResult:[],
      provider: new OpenStreetMapProvider({
        params: {
          'accept-language': 'ru',
          countrycodes: ['ru', 'kz'],
          addressdetails: 0,
          limit: 7,
        },
      }),
    };
  },
  props:{
    formItems:{
      type: Array,
      default: null
    },
    cols:{
      type: Number,
      default: 2
    },
    show_label:{
      type: Boolean,
      default: false
    },
    updated_event_bus:{
      type: String,
      default: ''
    }
  },
  mounted() {
    this.REQUEST_TYPES()
    this.loadCategories()
  },
  computed:{
    ...mapGetters({
      GET_REQUEST_TYPES:'user/GET_REQUEST_TYPES',
      GET_CATEGORIES:'user/GET_CATEGORIES',
    }),
    action(){
      let base_url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/api_rm52/v37`;
      if (window.location.hostname === 'localhost') {
        base_url = 'http://127.0.0.1:8000/api_rm52/v37';
      }
      return base_url + '/common/file/';
    },
    source_request(){
      return {
        model: 'source_request',
        type:'select',
        end_point:'organizations/sources/',
        validate: 'required',
      }
    },
    organization_request(){
      return {
        model: 'organization_request',
        type:'select',
        end_point:'organizations/organizations/',
        client_data_params:['region_fk'],
        use_last_request_params_event:true,
        clear_on_change:true,
        repeat_request_event:['responsible_manager'],
      }
    },
    region_fk(){
      return {
        model: 'region_fk',
        type:'select',
        end_point:'organizations/regions/',
        validate: 'required',
        repeat_request_event:['organization_request', 'responsible_manager'],
      }
    },
    level_request(){
      return {
        model: 'level_request',
        type:'select',
        end_point:'organizations/levels/',
        validate: 'required',
      }
    },
    request_type(){
      return {
        model: 'request_type',
        type:'select',
        end_point:'organizations/request_types/',
        validate: 'required',
      }
    },
    no_address_required(){
      return {
        model:'no_address_required',
        type:'checkbox',
        show_label: false,
        in_case:true,
        hide: ['map_requests'],
      }
    },
    map_requests(){
      return {
        model: 'map_requests',
        type:'map',
        show_label: false,
        hide_if: 'no_address_required',
        validate: 'required',
      }
    },
    name_input(){
      return {
        model: 'name',
        type:'input',
        validate: 'required',
      }
    },
    name_short_input(){
      return {
        model: 'name_short',
        type:'input',
        validate: 'required',
      }
    },
    bin(){
      return {
        model: 'bin',
        show_label: true,
        validate: 'required|bin_validation',
      }
    },
    address(){
      return {
        model: 'address',
        show_label: true,
      }
    },
    description(){
      return {
        model: 'description',
        type:'textarea',
        validate: 'required',
      }
    },
    health_threat(){
      return {
        model:'health_threat',
        type:'checkbox',
        show_label: false,
      }
    },
    responsible_manager(){
      return {
        model: 'responsible_manager',
        type:'select',
        end_point:'organizations/performer/',
        select_list_component:ProfileSelectView,
        client_data_params:['organization_request', 'region_fk'],
        use_last_request_params_event:true,
        clear_on_change:true,
      }
    },
    attachments(){
      return {
        model: 'attachments',
        type:'upload',
        read_only:false,
      }
    },
    parent(){
      return {
        model: 'parent',
        type:'select',
        end_point:'organizations/customer_select/',
      }
    },
    categories_of_requests(){
      return {
        model: 'categories_of_requests',
        validate: 'required',
      }
    },


  },
  methods: {
    loadCategories() {
      axios.get('/organizations/categories/')
          .then(response => {
            this.categories = response.data;
          })
          .catch(error => {
            console.error('Ошибка загрузки данных:', error);
          });
    },
    ...mapActions({
      REQUEST_TYPES:'user/REQUEST_TYPES',
    }),
    updateMarker(latLng) {
      console.log('latLng', latLng)
      // this.marker = latLng;
      // this.clientData['map_location'] = latLng;
    },
    async handleAdressChange(value) {
      console.log('value', value)
      if(value === '') {
        this.autoCompleteResult = []
        this.searchVisible = false
        this.deliveryPoint = {
          lat: null,
          lon: null,
          address: '',
          name: '',
        }
        this.$emit('markedPoint', this.deliveryPoint)
      }
      clearTimeout(timer)
      if(value.trim().length >= 3) {
        timer = setTimeout(() => {
          this.searchLoading = true
          try {
            this.provider.search({ query: value })
                .then((result)=> {
                  this.autoCompleteResult = result
                })
          } catch(e) {
            console.log(e)
          } finally {
            this.searchLoading = false
          }
        }, 700)
      }
    },
    click_add(item){
      let clone_form_items_clone = []
      this.form_items_clone.forEach((el)=>{
        if (el.model === item.model){
          if (item.model in this.control_model_name) {
            this.control_model_name[item.model] += 1
          } else {
            this.control_model_name[item.model] = 1
          }
          console.log('this.control_model_name', this.control_model_name)

          let sub_items_clone = []
          el.add_items.forEach((ele)=>{
            sub_items_clone.push({
              ...ele,
              model: `${ele.model}_${this.control_model_name[item.model]}`
            })
          })
          el.sub_items.push(sub_items_clone)
        }

        clone_form_items_clone.push(el)

      })
      console.log('clone_form_items_clone', clone_form_items_clone)
      this.form_items_clone = clone_form_items_clone
    },
    delete_sub_items(item, ele, key_ele){
      let clone_form_items_clone = []
      this.form_items_clone.forEach((el)=>{
        if (el.model === item.model){
          el.sub_items = el.sub_items.filter((ele_child)=>ele_child!==ele)
        }
        clone_form_items_clone.push(el)
      })
      console.log(item, ele, key_ele)
      let clone = {...this.clientData}
      ele.forEach((el)=>{
        delete clone[el.model]
      })
      this.clientData = clone
      this.form_items_clone = clone_form_items_clone

    },
    throw_off(){
      this.clientData = {}
    },
    clean_all_key(){
      let clone = {}
      Object.keys(this.clientData).forEach(key => {
        clone[key] = '';
      });
      this.clientData = clone
    },

    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
    },
    async customRequest({ file, onSuccess, onError, onProgress }) {
      const formData = new FormData();
      formData.append('upload', file);

      try {
        const response = await axios.post(this.action, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...(file.headers || {})
          },
          onUploadProgress: (event) => {
            onProgress({ percent: (event.loaded / event.total) * 100 });
          }
        });
        onSuccess(response.data);
      } catch (error) {
        onError(error);
      }
    },
    handleChange(info, item) {
      console.log('info', info)
      console.log('item', item)
      let clone_file_list = info.fileList.map((el)=>{
        console.log(el)
        if (el.response){
          return el.response.uid
        }
        return el.uid
      })
      this.clientData[item.model] = clone_file_list
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    selectHandleChange(val){
      this.clientData[val.name] = val.select
      this.updated_call()
    },
    showLabel(item) {
      return item.show_label === undefined || item.show_label === true;
    },
    isRequired(item) {
      return item.validate && item.validate.includes('required');
    },
    number_already_exists(errors){
      this.$emit('number_already_exists', errors);
      console.log('errors', errors);
    },
    iin_already_exists(errors){
      this.$emit('iin_already_exists', errors);
      console.log('errors', errors);
    },
    async default_change(value, item){
      console.log('name', value)
      console.log('item', item)
      this.clientData[item.model] = await value
      console.log('this.clientData', this.clientData)
    },
    async change_map(name, address){
      console.log('name', name)
      console.log('address', address)
      console.log('this.clientData', this.clientData)
      let clone = {...this.clientData}
      clone[name] = await address
      clone[`${name}_value`] = await address.full_address
      this.clientData = clone
    },
    change_checkbox(e, item){
      console.log('item', item)
      console.log('e', e)

      let clone = {...this.clientData}
      clone[item.model] = e.target.checked;
      this.clientData = clone
      if (item.hide){
        if (item.hide.length>0){
          if (e.target.checked === item.in_case){
            item.hide.forEach((ele)=>{
              this.hide_list.push(ele)
            })
          }else{
            item.hide.forEach((ele)=>{
              this.hide_list = this.hide_list.filter(item=>item!==ele)
            })
          }
        }
      }
      this.updated_call()

    },
    updated_call(){
      console.log('updated_call')
      // EventBus.$emit(this.updated_event_bus);
    }
  }
}
</script>

<style lang="scss">
.form-select{
  .ant-form-item-control{
    line-height: 30px;
  }
}
.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #d9d9d900 !important;
}
.ant-upload{
  width: 100%;
}
</style>
