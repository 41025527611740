<template>
  <div class="">
<!--    {{all_statuses}}-->
    <a-dropdown>
      <color-icon/>
      <a-menu slot="overlay">
        <a-menu-item @click="change_status(ele)" v-if="all_statuses" v-for="(ele, index) in all_statuses" :key="index">
          <a>
            <a-badge :color="ele.color" :text="ele.name" />
          </a>
        </a-menu-item>
        <a-menu-item  v-if="is_author_request">
          <a @click="handleEdit">
            {{$t('edit')}}
          </a>
        </a-menu-item>
        <a-menu-item v-if="is_author_request" >
          <a @click="handleDelete">
            {{$t('delete')}}
          </a>
        </a-menu-item>
        <a-menu-item v-if="take_over" >
          <a @click="handleTakeOver">
            {{$t('take_over')}}
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
<!--    <component :is="component_type" :trigger="['click']" >-->
<!--      <div>-->
<!--        <div v-if="is_watch">-->
<!--          <a-icon type="menu" class="menu-color-icon text-center cursor-pointer"/>-->
<!--        </div>-->
<!--        <div v-else>-->
<!--          <div v-if="take_over" @click="handleTakeOver">-->
<!--            {{$t('take_over')}}-->
<!--          </div>-->
<!--          <div v-else-if="text_chenge_status" @click="change_status(text_chenge_status)">-->
<!--            {{text_chenge_status.name}}-->
<!--          </div>-->
<!--          <div v-else>-->
<!--            <a-icon type="menu" class="menu-color-icon text-center cursor-pointer"/>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
<!--      <a-menu slot="overlay">-->
<!--        <a-menu-item v-if="is_watch">-->
<!--          <a @click="watch_request">-->
<!--            {{$t('watch')}}-->
<!--          </a>-->
<!--        </a-menu-item>-->
<!--        <a-menu-item @click="handleEdit" v-if="GET_PROFILE_INFO.can_edit_requests">-->
<!--          <a>-->
<!--            {{$t('edit')}}-->
<!--          </a>-->
<!--        </a-menu-item>-->
<!--        <a-menu-item @click="handleDelete" v-if="GET_PROFILE_INFO.can_edit_requests">-->
<!--          <a>-->
<!--            {{$t('delete')}}-->
<!--          </a>-->
<!--        </a-menu-item>-->

<!--      </a-menu>-->
<!--    </component>-->
  </div>
</template>

<script>
import RoleMixin from "@/Mixins/RoleMixin";
import {mapGetters} from "vuex";
import FormSaveMixin from "@/crm_components/form_save/FormSaveMixin";
import ColorIcon from "@/crm_components/icons/ColorIcon.vue";

export default {
  name: "Actions",
  components: {ColorIcon},
  props:{
    is_watch:{
      type: Boolean,
      default: true
    },
    data_request:{
      type:Object
    }
  },

  computed:{
    is_author_request(){
      let clone = false
      if (this.data_request && this.GET_PROFILE_INFO){
        if (this.data_request.author.uid === this.GET_PROFILE_INFO.uid){
          clone = true
        }
      }
      return clone
    },
    is_performer(){
      if (this.is_author_request){
        return false
      }
      if (this.GET_PROFILE_INFO.roles.find(el=>el.code==='performer')){
        return true
      }
      return false
    },
    all_statuses(){
      if (this.GET_GLOBAL_GLOBAL_SETTINGS_GET){
        if (this.is_author_request){
          return this.GET_GLOBAL_GLOBAL_SETTINGS_GET.show_which_statuses_button_inspector
        }
        if (this.is_performer){
          if (this.item.responsible_manager !== null){
            return this.GET_GLOBAL_GLOBAL_SETTINGS_GET.show_which_statuses_button_performer
          }
        }
      }
    },
    ...mapGetters({
      GET_PROFILE_INFO:'user/GET_PROFILE_INFO',
      GET_GLOBAL_SETTINGS:'user/GET_GLOBAL_SETTINGS',
      GET_GLOBAL_GLOBAL_SETTINGS_GET:'config/GET_GLOBAL_GLOBAL_SETTINGS_GET',
    }),
    component_type(){
      if (this.is_watch){
        return 'a-dropdown'
      }else{
        if(this.take_over){
          return 'a-dropdown-button'
        }else if (this.text_chenge_status){
          return 'a-dropdown-button'
        }else{
          return 'a-dropdown'
        }
      }
    },
    item(){
      console.log('this.params', this.params)
      if (this.params){
        return this.params.data
      }
      return this.data_request
    },
    available_statuses(){
      if (this.item.responsible_manager || this.item.author){
        if (this.GET_PROFILE_INFO){
          if ((this.item.responsible_manager && this.item.responsible_manager.uid === this.GET_PROFILE_INFO.uid) ||
              (this.item.author && this.item.author.uid === this.GET_PROFILE_INFO.uid)){
            let can_set_statuses = this.GET_PROFILE_INFO.request_permissions.can_set_statuses
            let exception_current_status = can_set_statuses.filter(ele=>ele.uid !== this.item.status_request.uid)
            return exception_current_status
          }
        }
      }
    },
    text_chenge_status(){
      try {
        if (this.item.responsible_manager || this.item.author){
          if ((this.item.responsible_manager && this.item.responsible_manager.uid === this.GET_PROFILE_INFO.uid) ||
              (this.item.author && this.item.author.uid === this.GET_PROFILE_INFO.uid)){
            if (this.GET_PROFILE_INFO){
              let can_set_statuses = this.GET_PROFILE_INFO.request_permissions.can_set_statuses
              let filter_show_which_statuses_button = []
              if (this.GET_GLOBAL_SETTINGS){
                let show_which_statuses_button
                if (this.item.author.uid === this.GET_PROFILE_INFO.uid){
                  show_which_statuses_button = this.GET_GLOBAL_SETTINGS[0].show_which_statuses_button_performer
                }else{
                  show_which_statuses_button = this.GET_GLOBAL_SETTINGS[0].show_which_statuses_button_inspector
                }

                can_set_statuses.forEach((ele)=>{
                  if (show_which_statuses_button.find(element=>element.uid===ele.uid)){
                    filter_show_which_statuses_button.push(ele)
                  }
                })
              }
              let current_status_index = filter_show_which_statuses_button.findIndex(ele=>ele.sort > this.item.status_request.sort)
              if (current_status_index === -1){
                return false
              }
              let next_status = filter_show_which_statuses_button[current_status_index+1]
              console.log('next_status', next_status)
              return next_status
            }
          }
        }
      }catch (e) {
        console.log('e', e)
      }
    },
    take_over(){
      if (this.item){
        if (this.item.responsible_manager === null){
          if (this.is_performer){
            return true
          }
        }
      }

    }
  },
  methods: {
    handleEdit() {
      if (this.params){
        this.params.onEditClicked(this.params)
      }
      this.$emit('onEditClicked');
    },
    handleDelete() {
      if (this.params){
        this.params.onDeleteClicked(this.params)
      }
      this.$emit('onDeleteClicked');
    },
    watch_request(){
      if (this.params){
        this.params.onWatchClicked(this.params)
      }
      this.$emit('onWatchClicked');
    },
    afterUpdate(val){
      console.log('afterUpdate val', val)
      if (this.params){
        this.params.afterUpdate(val)
      }
      this.$emit('update_request', val)
    },
    change_status(val){
      let data = {
        clientData:{
          uid:this.item.uid,
          status_request:val.uid,
        }
      }
      this.handleSubmit(
          data,
          'pages_customer_request_list',
          `organizations/drawer_change_status/`,
          'update',
      )
    },
    handleTakeOver(){
      let data = {
        clientData:{
          uid:this.item.uid,
        }
      }
      this.handleSubmit(
          data,
          'pages_customer_request_list',
          `organizations/drawer_take_over/`,
          'update',
      )
    },

  },
  mixins:[
    RoleMixin,
    FormSaveMixin
  ]
}
</script>

<style lang="scss">
@import "src/scss/base/_variables.scss";
.menu-color-icon{
  color: $color-primary;
  font-size: 20px;
}
//.menu-color-icon {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//}
</style>
