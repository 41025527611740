<template>
  <div>
    <custom-drawer :visible="visible" @close="close">
      <template slot="title">
        <div>
          {{ $t('add') }}
        </div>
      </template>
      <template slot="content">
        <div style="margin-top: 50px;">
          <div>
            <form-select-create-users ref="refFormSelectCreateUsers"/>
          </div>
        </div>
      </template>
      <template slot="content_bottom">
        <a-button
            class="default-button mr-1"
            size="small"
            @click="create_create_users">
          {{$t('save')}}
        </a-button>
      </template>
    </custom-drawer>
  </div>
</template>

<script>
import CustomDrawer from "@/crm_components/CustomDrawer/CustomDrawer.vue";
import {EventBus} from "@/configs/eventBus";
import {mapActions, mapGetters} from "vuex";
import FormSelectCreateUsers from "@/pages/Leaf/drawers/form_create_user/c/FormSelectCreateUsers.vue";
import FormSaveMixin from "@/crm_components/form_save/FormSaveMixin.vue";
export default {
  name: "FormCreateUser",
  data(){
    return{
      visible:false,
    }
  },
  async mounted() {
    EventBus.$on('open_form_create_user', this.open_form_create_user);
  },
  components: {
    FormSelectCreateUsers,
    CustomDrawer,
  },
  computed:{

  },
  mixins:[
    FormSaveMixin,
  ],
  methods:{
    close(){
      this.visible=false
    },
    open_form_create_user(){
      console.log('addsadasddsasadds')
      this.visible = true
    },
    async create_create_users(){
      await this.handleSubmit(
          this.$refs.refFormSelectCreateUsers,
          'client_and_request_handle_submit',
          'organizations/drawer_create_user_create/',
          'create',
          null,
      )
    }
  }
}
</script>

<style lang="scss">
.ant-avatar-string{
  display: flex;
  align-items: center;
  height: 100%;
}
</style>