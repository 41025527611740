var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"refValidationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var invalid = ref.invalid;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-sm-12"},[_c('div',{staticClass:"col-12 mb-2"},[_c('span',{staticClass:"w-1/2 mb-2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.name_input.model))+": "),(_vm.isRequired(_vm.name_input))?_c('span',{staticClass:"text-red-500"},[_vm._v("*")]):_vm._e()]),_c('div',[_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":'required',"name":'name'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.name_input.mask)?_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.name_input.mask),expression:"name_input.mask"}],attrs:{"disabled":_vm.name_input.read_only === true,"name":_vm.name_input.model,"placeholder":_vm.name_input.placeholder ? _vm.$t(_vm.name_input.placeholder) : _vm.$t(_vm.name_input.model)},model:{value:(_vm.clientData[_vm.name_input.model]),callback:function ($$v) {_vm.$set(_vm.clientData, _vm.name_input.model, $$v)},expression:"clientData[name_input.model]"}}):_c('a-input',{attrs:{"disabled":_vm.name_input.read_only === true,"name":_vm.name_input.model,"placeholder":_vm.name_input.placeholder ? _vm.$t(_vm.name_input.placeholder) : _vm.$t(_vm.name_input.model)},model:{value:(_vm.clientData[_vm.name_input.model]),callback:function ($$v) {_vm.$set(_vm.clientData, _vm.name_input.model, $$v)},expression:"clientData[name_input.model]"}}),(errors.length>0)?_c('div',{staticStyle:{"line-height":"normal"}},[_c('span',{staticClass:"text-red-500"},[_vm._v(" "+_vm._s(errors[0])+" ")])]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 mb-2"},[_c('span',{staticClass:"w-1/2 mb-2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.name_short_input.model))+": "),(_vm.isRequired(_vm.name_short_input))?_c('span',{staticClass:"text-red-500"},[_vm._v("*")]):_vm._e()]),_c('div',[_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":'required',"name":'name'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.name_short_input.mask)?_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.name_short_input.mask),expression:"name_short_input.mask"}],attrs:{"disabled":_vm.name_short_input.read_only === true,"name":_vm.name_short_input.model,"placeholder":_vm.name_short_input.placeholder ? _vm.$t(_vm.name_short_input.placeholder) : _vm.$t(_vm.name_short_input.model)},model:{value:(_vm.clientData[_vm.name_short_input.model]),callback:function ($$v) {_vm.$set(_vm.clientData, _vm.name_short_input.model, $$v)},expression:"clientData[name_short_input.model]"}}):_c('a-input',{attrs:{"disabled":_vm.name_short_input.read_only === true,"name":_vm.name_short_input.model,"placeholder":_vm.name_short_input.placeholder ? _vm.$t(_vm.name_short_input.placeholder) : _vm.$t(_vm.name_short_input.model)},model:{value:(_vm.clientData[_vm.name_short_input.model]),callback:function ($$v) {_vm.$set(_vm.clientData, _vm.name_short_input.model, $$v)},expression:"clientData[name_short_input.model]"}}),(errors.length>0)?_c('div',{staticStyle:{"line-height":"normal"}},[_c('span',{staticClass:"text-red-500"},[_vm._v(" "+_vm._s(errors[0])+" ")])]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 mb-2"},[_c('span',{staticClass:"w-1/2 mb-2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.bin.model))+": "),(_vm.isRequired(_vm.bin))?_c('span',{staticClass:"text-red-500"},[_vm._v("*")]):_vm._e()]),_c('div',[_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":'required',"name":'name'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.bin.mask)?_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.bin.mask),expression:"bin.mask"}],attrs:{"disabled":_vm.bin.read_only === true,"name":_vm.bin.model,"placeholder":_vm.bin.placeholder ? _vm.$t(_vm.bin.placeholder) : _vm.$t(_vm.bin.model)},model:{value:(_vm.clientData[_vm.bin.model]),callback:function ($$v) {_vm.$set(_vm.clientData, _vm.bin.model, $$v)},expression:"clientData[bin.model]"}}):_c('a-input',{attrs:{"disabled":_vm.bin.read_only === true,"name":_vm.bin.model,"placeholder":_vm.bin.placeholder ? _vm.$t(_vm.bin.placeholder) : _vm.$t(_vm.bin.model)},model:{value:(_vm.clientData[_vm.bin.model]),callback:function ($$v) {_vm.$set(_vm.clientData, _vm.bin.model, $$v)},expression:"clientData[bin.model]"}}),(errors.length>0)?_c('div',{staticStyle:{"line-height":"normal"}},[_c('span',{staticClass:"text-red-500"},[_vm._v(" "+_vm._s(errors[0])+" ")])]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 mb-2"},[_c('span',{staticClass:"w-1/2 mb-2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.address.model))+": "),(_vm.isRequired(_vm.address))?_c('span',{staticClass:"text-red-500"},[_vm._v("*")]):_vm._e()]),_c('div',[_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":'required',"name":'name'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.address.mask)?_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.address.mask),expression:"address.mask"}],attrs:{"disabled":_vm.address.read_only === true,"name":_vm.address.model,"placeholder":_vm.address.placeholder ? _vm.$t(_vm.address.placeholder) : _vm.$t(_vm.address.model)},model:{value:(_vm.clientData[_vm.address.model]),callback:function ($$v) {_vm.$set(_vm.clientData, _vm.address.model, $$v)},expression:"clientData[address.model]"}}):_c('a-input',{attrs:{"disabled":_vm.address.read_only === true,"name":_vm.address.model,"placeholder":_vm.address.placeholder ? _vm.$t(_vm.address.placeholder) : _vm.$t(_vm.address.model)},model:{value:(_vm.clientData[_vm.address.model]),callback:function ($$v) {_vm.$set(_vm.clientData, _vm.address.model, $$v)},expression:"clientData[address.model]"}}),(errors.length>0)?_c('div',{staticStyle:{"line-height":"normal"}},[_c('span',{staticClass:"text-red-500"},[_vm._v(" "+_vm._s(errors[0])+" ")])]):_vm._e()]}}],null,true)})],1)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }