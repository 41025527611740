<template xmlns="http://www.w3.org/1999/html">
  <ValidationObserver v-slot="{ validate, invalid }" ref="refValidationObserver">

    <div class="row">

      <div class="col-md-4 col-sm-12">

        <div class="col-12 mb-2">
          <span class="w-1/2 mb-2">
            {{ $t(name_input.model)}}:
            <span class="text-red-500" v-if="isRequired(name_input)">*</span>
          </span>
          <div>
            <ValidationProvider :rules="'required'" v-slot="{ errors }" :name="'name'" class="w-full" >
              <a-input
                  :disabled="name_input.read_only === true"
                  v-if="name_input.mask"
                  v-mask="name_input.mask"
                  :name="name_input.model"
                  :placeholder="name_input.placeholder ? $t(name_input.placeholder) : $t(name_input.model)"
                  v-model="clientData[name_input.model]"/>
              <a-input
                  v-else
                  :disabled="name_input.read_only === true"
                  :name="name_input.model"
                  :placeholder="name_input.placeholder ? $t(name_input.placeholder) : $t(name_input.model)"
                  v-model="clientData[name_input.model]"/>
              <div v-if="errors.length>0" style="line-height: normal;">
                <span class="text-red-500">
                  {{ errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div class="col-12 mb-2">
          <checkbox-form-select
              :disabled="health_threat.read_only === true"
              :use_router_replace="health_threat.use_router_replace"
              :name="health_threat.model"
              :label="$t(health_threat.model)"
              v-model="clientData[health_threat.model]"
              @change="change_checkbox($event, health_threat)"/>
        </div>

        <div class="col-12 mb-2">
          <span class="w-1/2 mb-2">
            {{ $t(description.model)}}:
            <span class="text-red-500" v-if="isRequired(description)">*</span>
          </span>
          <div>
            <ValidationProvider :rules="'required'" v-slot="{ errors }" :name="'description'" class="w-full" >
              <a-textarea
                  :disabled="description.read_only === true"
                  v-model="clientData[description.model]"
                  :placeholder="description.placeholder ? $t(description.placeholder) : $t(description.model)"
                  :rows="3"/>
              <div v-if="errors.length>0" style="line-height: normal;">
                <span class="text-red-500">
                  {{ errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </div>
        </div>
<!--        <div class="col-12 mb-2">-->
<!--          <span class="w-1/2 mb-2">-->
<!--            {{ $t(request_type.model)}}:-->
<!--            <span class="text-red-500" v-if="showLabel(request_type)">*</span>-->
<!--          </span>-->
<!--          <div>-->
<!--            <ValidationProvider :rules="request_type.validate" v-slot="{ errors }" :name="request_type.model" class="w-full" >-->
<!--              <ant-select-->
<!--                  :mode="request_type.mode"-->
<!--                  :clear_on_change="request_type.clear_on_change"-->
<!--                  :client_data_params="request_type.client_data_params"-->
<!--                  :use_last_request_params_event="request_type.use_last_request_params_event"-->
<!--                  :repeat_request_event="request_type.repeat_request_event"-->
<!--                  :clientData="clientData"-->
<!--                  :disabled="request_type.read_only === true"-->
<!--                  :use_router_replace="request_type.use_router_replace"-->
<!--                  :filter_field="request_type.filter_field"-->
<!--                  v-model="clientData[request_type.model]"-->
<!--                  @input="selectHandleChange"-->
<!--                  :placeholder="request_type.placeholder ? $t(request_type.placeholder) : $t(request_type.model)"-->
<!--                  :end_point="request_type.end_point"-->
<!--                  :select_list_component="request_type.select_list_component"-->
<!--                  :showSearch="true"-->
<!--                  :use_pagination="true"-->
<!--                  :name="request_type.model"/>-->
<!--                <div v-if="errors.length>0" style="line-height: normal;">-->
<!--                    <span class="text-red-500">-->
<!--                      {{ errors[0] }}-->
<!--                    </span>-->
<!--                </div>-->
<!--            </ValidationProvider>-->
<!--          </div>-->
<!--        </div>-->
        <div class="col-12 mb-2">
            <span class="w-1/2 mb-2">
              {{ $t(level_request.model)}}:
              <span class="text-red-500" v-if="isRequired(level_request)">*</span>
            </span>
            <div>
              <ValidationProvider :rules="level_request.validate" v-slot="{ errors }" :name="level_request.model" class="w-full" >
                <ant-select
                    :mode="level_request.mode"
                    :clear_on_change="level_request.clear_on_change"
                    :client_data_params="level_request.client_data_params"
                    :use_last_request_params_event="level_request.use_last_request_params_event"
                    :repeat_request_event="level_request.repeat_request_event"
                    :clientData="clientData"
                    :disabled="level_request.read_only === true"
                    :use_router_replace="level_request.use_router_replace"
                    :filter_field="level_request.filter_field"
                    v-model="clientData[level_request.model]"
                    @input="selectHandleChange"
                    :placeholder="level_request.placeholder ? $t(level_request.placeholder) : $t(level_request.model)"
                    :end_point="level_request.end_point"
                    :select_list_component="level_request.select_list_component"
                    :showSearch="true"
                    :use_pagination="true"
                    :name="level_request.model"/>
                <div v-if="errors.length>0" style="line-height: normal;">
                  <span class="text-red-500">
                    {{ errors[0] }}
                  </span>
                </div>
              </ValidationProvider>
            </div>
          </div>

        <div class="col-12 mb-2">
          <span class="w-1/2 mb-2">
            {{ $t(source_request.model)}}:
            <span class="text-red-500" v-if="isRequired(source_request)">*</span>
          </span>
          <div>
            <ValidationProvider :rules="source_request.validate" v-slot="{ errors }" :name="source_request.model" class="w-full" >
              <ant-select
                    :mode="source_request.mode"
                    :clear_on_change="source_request.clear_on_change"
                    :client_data_params="source_request.client_data_params"
                    :use_last_request_params_event="source_request.use_last_request_params_event"
                    :repeat_request_event="source_request.repeat_request_event"
                    :clientData="clientData"
                    :disabled="source_request.read_only === true"
                    :use_router_replace="source_request.use_router_replace"
                    :filter_field="source_request.filter_field"
                    v-model="clientData[source_request.model]"
                    @input="selectHandleChange"
                    :placeholder="source_request.placeholder ? $t(source_request.placeholder) : $t(source_request.model)"
                    :end_point="source_request.end_point"
                    :select_list_component="source_request.select_list_component"
                    :showSearch="true"
                    :use_pagination="true"
                    :name="source_request.model"/>
                <div v-if="errors.length>0" style="line-height: normal;">
                  <span class="text-red-500">
                    {{ errors[0] }}
                  </span>
                </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 mb-2">
          <span class="w-1/2 mb-2">
            {{ $t(organization_request.model)}}:
            <span class="text-red-500" v-if="isRequired(organization_request)">*</span>
          </span>
          <div>
            <ValidationProvider :rules="organization_request.validate" v-slot="{ errors }" :name="organization_request.model" class="w-full" >
              <ant-select
                    :mode="organization_request.mode"
                    :clear_on_change="organization_request.clear_on_change"
                    :client_data_params="organization_request.client_data_params"
                    :use_last_request_params_event="organization_request.use_last_request_params_event"
                    :repeat_request_event="organization_request.repeat_request_event"
                    :clientData="clientData"
                    :disabled="organization_request.read_only === true"
                    :use_router_replace="organization_request.use_router_replace"
                    :filter_field="organization_request.filter_field"
                    v-model="clientData[organization_request.model]"
                    @input="selectHandleChange"
                    :placeholder="organization_request.placeholder ? $t(organization_request.placeholder) : $t(organization_request.model)"
                    :end_point="organization_request.end_point"
                    :select_list_component="organization_request.select_list_component"
                    :showSearch="true"
                    :use_pagination="true"
                    :name="organization_request.model"/>
                <div v-if="errors.length>0" style="line-height: normal;">
                  <span class="text-red-500">
                    {{ errors[0] }}
                  </span>
                </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 mb-2">
            <span class="w-1/2 mb-2">
              {{ $t(responsible_manager.model)}}:
              <span class="text-red-500" v-if="isRequired(responsible_manager)">*</span>
            </span>
          <div>
            <ValidationProvider :rules="responsible_manager.validate" v-slot="{ errors }" :name="responsible_manager.model" class="w-full" >
              <ant-select
                  :mode="responsible_manager.mode"
                  :clear_on_change="responsible_manager.clear_on_change"
                  :client_data_params="responsible_manager.client_data_params"
                  :use_last_request_params_event="responsible_manager.use_last_request_params_event"
                  :repeat_request_event="responsible_manager.repeat_request_event"
                  :clientData="clientData"
                  :disabled="responsible_manager.read_only === true"
                  :use_router_replace="responsible_manager.use_router_replace"
                  :filter_field="responsible_manager.filter_field"
                  v-model="clientData[responsible_manager.model]"
                  @input="selectHandleChange"
                  :placeholder="responsible_manager.placeholder ? $t(responsible_manager.placeholder) : $t(responsible_manager.model)"
                  :end_point="responsible_manager.end_point"
                  :select_list_component="responsible_manager.select_list_component"
                  :showSearch="true"
                  :use_pagination="true"
                  :name="responsible_manager.model"/>
              <div v-if="errors.length>0" style="line-height: normal;">
                  <span class="text-red-500">
                    {{ errors[0] }}
                  </span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 mb-2">
          <span class="w-1/2 mb-2">
            {{ $t(categories_of_requests.model)}}:
            <span class="text-red-500" v-if="isRequired(categories_of_requests)">*</span>
          </span>
          <div>
            <ValidationProvider :rules="categories_of_requests.validate" v-slot="{ errors }" :name="categories_of_requests.model" class="w-full" >
              <treeselect
                  openDirection="top"
                  v-model="clientData['categories_of_requests']"
                  :options="GET_CATEGORIES"
                  :disable-branch-nodes="true"
                  placeholder="Выберите категорию"
                  :name="categories_of_requests.model"
              />
              <div v-if="errors.length>0" style="line-height: normal;">
                  <span class="text-red-500">
                    {{ errors[0] }}
                  </span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 mb-2">
            <span class="w-1/2 mb-2">
              {{ $t(parent.model)}}:
              <span class="text-red-500" v-if="isRequired(parent)">*</span>
            </span>
          <div>
            <ValidationProvider :rules="parent.validate" v-slot="{ errors }" :name="parent.model" class="w-full" >
              <ant-select
                  :mode="parent.mode"
                  :clear_on_change="parent.clear_on_change"
                  :client_data_params="parent.client_data_params"
                  :use_last_request_params_event="parent.use_last_request_params_event"
                  :repeat_request_event="parent.repeat_request_event"
                  :clientData="clientData"
                  :disabled="parent.read_only === true"
                  :use_router_replace="parent.use_router_replace"
                  :filter_field="parent.filter_field"
                  v-model="clientData[parent.model]"
                  @input="selectHandleChange"
                  :placeholder="parent.placeholder ? $t(parent.placeholder) : $t(parent.model)"
                  :end_point="parent.end_point"
                  :select_list_component="parent.select_list_component"
                  :showSearch="true"
                  :use_pagination="true"
                  :name="parent.model"/>
              <div v-if="errors.length>0" style="line-height: normal;">
                  <span class="text-red-500">
                    {{ errors[0] }}
                  </span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 mb-2">
            <span class="w-1/2 mb-2">
              {{ $t(attachments.model)}}:
              <span class="text-red-500" v-if="isRequired(attachments)">*</span>
            </span>
          <div>
            <ValidationProvider :rules="attachments.validate" v-slot="{ errors }" :name="attachments.model" class="w-full" >
              <a-upload
                  style="width: 100%;"
                  :disabled="attachments.read_only === true"
                  list-type="picture"
                  ref="refUpload"
                  name="upload"
                  :action="action"
                  :headers="headers"
                  :default-file-list="clientData[attachments.model]"
                  :custom-request="customRequest"
                  :before-upload="beforeUpload"
                  @change="handleChange($event, attachments)">
                <a-button v-if="attachments.read_only !== true" style="width: 100%;"> <a-icon type="upload" /> Select File </a-button>
              </a-upload>
            </ValidationProvider>
          </div>
        </div>
      </div>

      <div class="col-md-8 col-sm-12">
        <div class="row">

          <div class="col-md-4 col-sm-12 mb-2">
            <span class="w-1/2 mb-2">
              {{ $t(region_fk.model)}}:
              <span class="text-red-500" v-if="isRequired(region_fk)">*</span>
            </span>
            <div>
              <ValidationProvider :rules="region_fk.validate" v-slot="{ errors }" :name="region_fk.model" class="w-full" >
                <ant-select
                    :mode="region_fk.mode"
                    :clear_on_change="region_fk.clear_on_change"
                    :client_data_params="region_fk.client_data_params"
                    :use_last_request_params_event="region_fk.use_last_request_params_event"
                    :repeat_request_event="region_fk.repeat_request_event"
                    :clientData="clientData"
                    :disabled="region_fk.read_only === true"
                    :use_router_replace="region_fk.use_router_replace"
                    :filter_field="region_fk.filter_field"
                    v-model="clientData[region_fk.model]"
                    @input="selectHandleChange"
                    :placeholder="region_fk.placeholder ? $t(region_fk.placeholder) : $t(region_fk.model)"
                    :end_point="region_fk.end_point"
                    :select_list_component="region_fk.select_list_component"
                    :showSearch="true"
                    :use_pagination="true"
                    :name="region_fk.model"/>
                <div v-if="errors.length>0" style="line-height: normal;">
                  <span class="text-red-500">
                    {{ errors[0] }}
                  </span>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-md-8 col-sm-12">
            <div class="col-12 mb-2">
              <checkbox-form-select
                  :disabled="no_address_required.read_only === true"
                  :use_router_replace="no_address_required.use_router_replace"
                  :name="no_address_required.model"
                  :label="$t(no_address_required.model)"
                  v-model="clientData[no_address_required.model]"
                  @change="change_checkbox($event, no_address_required)"/>
            </div>
            <div class="col-12 mb-2" v-if="clientData['no_address_required'] !== true">
              <ValidationProvider :rules="map_requests.validate" v-slot="{ errors }" :name="map_requests.model" class="w-full" >
                <a-input v-model="clientData[`${map_requests.model}_value`]" style="width: 100%;" disabled :name="map_requests.model"/>
                <div v-if="errors.length>0" style="line-height: normal;">
                  <span class="text-red-500">
                    {{ errors[0] }}
                  </span>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-12 mb-2" v-if="clientData['no_address_required'] !== true">
            <l-control class="searsh-form" style="width: 100%;" name="map">
              <a-form-item style="margin-bottom: 0px;">
                <a-auto-complete
                    style="width: 100%;"
                    class="auto-complete"
                    :getPopupContainer="trigger => trigger.parentElement"
                    @change="handleAdressChange">
                  <template slot="dataSource">
                    <a-select-option
                        v-for="(result, i) in autoCompleteResult"
                        :key=String(i)
                        @click="placeMarker(result, map_requests)">
                      {{ result.label }}
                    </a-select-option>
                  </template>
                  <a-input-search placeholder="Поиск на карте"
                                  allowClear>
                  </a-input-search>
                </a-auto-complete>
              </a-form-item>
            </l-control>
            <l-map @click="onMapClick($event, map_requests)" @dblclick="onMapClick($event, map_requests)" :zoom="zoom" :center="center" style="height: 400px; width: 100%">
              <l-tile-layer :url="url" />
              <l-marker
                  v-for="(point, n) in markers"
                  :key="n"
                  :icon="customIcon"
                  :lat-lng="[point.lat, point.lng]">
                <l-tooltip>{{ point.name }}</l-tooltip>
              </l-marker>
            </l-map>
          </div>
          <div class="col-12 mb-2">
            <ValidationProvider :rules="'required'" v-slot="{ errors }" :name="'request_type'" class="w-full" >
              <a-radio-group v-model="clientData['request_type']" :name="'request_type'">
                <a-radio-button v-for="(item, index) in GET_REQUEST_TYPES" :key="index" :value="item.uid" class="m-2" style="border-radius: 6px; border: 1px solid #d9d9d9;">
                  {{item.name}}
                </a-radio-button>
              </a-radio-group>
              <div v-if="errors.length>0" style="line-height: normal;">
                  <span class="text-red-500">
                    {{ errors[0] }}
                  </span>
              </div>
            </ValidationProvider>

          </div>


        </div>
      </div>
    </div>

  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from '@/configs/vee-validate';
import axios from "@/configs/axios";
import AntSelect from "@/components/AntSelect/AntSelect";
import TreeSelect from "@/components/AntSelect/TreeSelect";
import { mask } from 'vue-the-mask'
import EditFiles from "@/crm_components/files/EditFiles";
import GoogleMap from "@/crm_components/GoogleMap/GoogleMap";
import MapMixin from "@/crm_components/GoogleMap/MapMixin";
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import DateStartEnd from "@/components/Forms/FormAddClient/DateStartEnd";
import CheckboxFormSelect from "@/components/Forms/FormAddClient/CompsFormSelect/CheckboxFormSelect";
import {EventBus} from "@/configs/eventBus";
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import {mapActions, mapGetters} from "vuex";
import ProfileSelectView from "@/components/AntSelect/ProfileSelectView.vue";
let timer
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "FormSelectRequest",
  components:{
    Treeselect,
    CheckboxFormSelect,
    DateStartEnd,
    AntSelect,
    ValidationProvider,
    ValidationObserver,
    TreeSelect,
    EditFiles,
    GoogleMap,
    LMap,
    LTileLayer,
    LMarker,
  },
  mixins:[
      MapMixin
  ],
  directives: {
    mask
  },
  data() {
    return {
      categories: [],
      clientData: {},
      initial_form: {},
      fileList: [],
      form: this.$form.createForm(this, { name: 'coordinated' }),
      headers:{
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).access,
      },
      hide_list:[],
      customIcon:L.icon({
        iconUrl: 'https://cdn-icons-png.flaticon.com/512/443/443025.png',
        iconSize: [30, 30],
      }),
      form_items_clone: this.formItems,
      control_model_name:{},
      zoom: 13,
      marker: [51.505, -0.09],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      autoCompleteResult:[],
      provider: new OpenStreetMapProvider({
        params: {
          'accept-language': 'ru',
          countrycodes: ['ru', 'kz'],
          addressdetails: 0,
          limit: 7,
        },
      }),
    };
  },
  props:{
    formItems:{
      type: Array,
      default: null
    },
    cols:{
      type: Number,
      default: 2
    },
    show_label:{
      type: Boolean,
      default: false
    },
    updated_event_bus:{
      type: String,
      default: ''
    }
  },
  mounted() {
    this.REQUEST_TYPES()
    this.loadCategories()
  },
  computed:{
    ...mapGetters({
      GET_REQUEST_TYPES:'user/GET_REQUEST_TYPES',
      GET_CATEGORIES:'user/GET_CATEGORIES',
    }),
    action(){
      let base_url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/api_rm52/v37`;
      if (window.location.hostname === 'localhost') {
        base_url = 'http://127.0.0.1:8000/api_rm52/v37';
      }
      return base_url + '/common/file/';
    },
    source_request(){
      return {
        model: 'source_request',
        type:'select',
        end_point:'organizations/sources/',
        validate: 'required',
      }
    },
    organization_request(){
      return {
        model: 'organization_request',
        type:'select',
        end_point:'organizations/organizations/',
        client_data_params:['region_fk'],
        use_last_request_params_event:true,
        clear_on_change:true,
        repeat_request_event:['responsible_manager'],
      }
    },
    region_fk(){
      return {
        model: 'region_fk',
        type:'select',
        end_point:'organizations/regions/',
        validate: 'required',
        repeat_request_event:['organization_request', 'responsible_manager'],
      }
    },
    level_request(){
      return {
        model: 'level_request',
        type:'select',
        end_point:'organizations/levels/',
        validate: 'required',
      }
    },
    request_type(){
      return {
        model: 'request_type',
        type:'select',
        end_point:'organizations/request_types/',
        validate: 'required',
      }
    },
    no_address_required(){
      return {
        model:'no_address_required',
        type:'checkbox',
        show_label: false,
        in_case:true,
        hide: ['map_requests'],
      }
    },
    map_requests(){
      return {
        model: 'map_requests',
        type:'map',
        show_label: false,
        hide_if: 'no_address_required',
        validate: 'required',
      }
    },
    name_input(){
      return {
        model: 'name',
        type:'input',
        validate: 'required',
      }
    },
    description(){
      return {
        model: 'description',
        type:'textarea',
        validate: 'required',
      }
    },
    health_threat(){
      return {
        model:'health_threat',
        type:'checkbox',
        show_label: false,
      }
    },
    responsible_manager(){
      return {
        model: 'responsible_manager',
        type:'select',
        end_point:'organizations/performer/',
        select_list_component:ProfileSelectView,
        client_data_params:['organization_request', 'region_fk'],
        use_last_request_params_event:true,
        clear_on_change:true,
      }
    },
    attachments(){
      return {
        model: 'attachments',
        type:'upload',
        read_only:false,
      }
    },
    parent(){
      return {
        model: 'parent',
        type:'select',
        end_point:'organizations/customer_select/',
      }
    },
    categories_of_requests(){
      return {
        model: 'categories_of_requests',
        validate: 'required',
      }
    },


  },
  methods: {
    loadCategories() {
      axios.get('/organizations/categories/')
          .then(response => {
            this.categories = response.data;
          })
          .catch(error => {
            console.error('Ошибка загрузки данных:', error);
          });
    },
    ...mapActions({
      REQUEST_TYPES:'user/REQUEST_TYPES',
    }),
    updateMarker(latLng) {
      console.log('latLng', latLng)
      // this.marker = latLng;
      // this.clientData['map_location'] = latLng;
    },
    async handleAdressChange(value) {
      console.log('value', value)
      if(value === '') {
        this.autoCompleteResult = []
        this.searchVisible = false
        this.deliveryPoint = {
          lat: null,
          lon: null,
          address: '',
          name: '',
        }
        this.$emit('markedPoint', this.deliveryPoint)
      }
      clearTimeout(timer)
      if(value.trim().length >= 3) {
        timer = setTimeout(() => {
          this.searchLoading = true
          try {
            this.provider.search({ query: value })
                .then((result)=> {
                  this.autoCompleteResult = result
                })
          } catch(e) {
            console.log(e)
          } finally {
            this.searchLoading = false
          }
        }, 700)
      }
    },
    click_add(item){
      let clone_form_items_clone = []
      this.form_items_clone.forEach((el)=>{
        if (el.model === item.model){
          if (item.model in this.control_model_name) {
            this.control_model_name[item.model] += 1
          } else {
            this.control_model_name[item.model] = 1
          }
          console.log('this.control_model_name', this.control_model_name)

          let sub_items_clone = []
          el.add_items.forEach((ele)=>{
            sub_items_clone.push({
              ...ele,
              model: `${ele.model}_${this.control_model_name[item.model]}`
            })
          })
          el.sub_items.push(sub_items_clone)
        }

        clone_form_items_clone.push(el)

      })
      console.log('clone_form_items_clone', clone_form_items_clone)
      this.form_items_clone = clone_form_items_clone
    },
    delete_sub_items(item, ele, key_ele){
      let clone_form_items_clone = []
      this.form_items_clone.forEach((el)=>{
        if (el.model === item.model){
          el.sub_items = el.sub_items.filter((ele_child)=>ele_child!==ele)
        }
        clone_form_items_clone.push(el)
      })
      console.log(item, ele, key_ele)
      let clone = {...this.clientData}
      ele.forEach((el)=>{
        delete clone[el.model]
      })
      this.clientData = clone
      this.form_items_clone = clone_form_items_clone

    },
    throw_off(){
      this.clientData = {}
    },
    clean_all_key(){
      let clone = {}
      Object.keys(this.clientData).forEach(key => {
        clone[key] = '';
      });
      this.clientData = clone
    },

    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
    },
    async customRequest({ file, onSuccess, onError, onProgress }) {
      const formData = new FormData();
      formData.append('upload', file);

      try {
        const response = await axios.post(this.action, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...(file.headers || {})
          },
          onUploadProgress: (event) => {
            onProgress({ percent: (event.loaded / event.total) * 100 });
          }
        });
        onSuccess(response.data);
      } catch (error) {
        onError(error);
      }
    },
    handleChange(info, item) {
      console.log('info', info)
      console.log('item', item)
      let clone_file_list = info.fileList.map((el)=>{
        console.log(el)
        if (el.response){
          return el.response.uid
        }
        return el.uid
      })
      this.clientData[item.model] = clone_file_list
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    selectHandleChange(val){
      this.clientData[val.name] = val.select
      this.updated_call()
    },
    showLabel(item) {
      return item.show_label === undefined || item.show_label === true;
    },
    isRequired(item) {
      return item.validate && item.validate.includes('required');
    },
    number_already_exists(errors){
      this.$emit('number_already_exists', errors);
      console.log('errors', errors);
    },
    iin_already_exists(errors){
      this.$emit('iin_already_exists', errors);
      console.log('errors', errors);
    },
    async default_change(value, item){
      console.log('name', value)
      console.log('item', item)
      this.clientData[item.model] = await value
      console.log('this.clientData', this.clientData)
    },
    async change_map(name, address){
      console.log('name', name)
      console.log('address', address)
      console.log('this.clientData', this.clientData)
      let clone = {...this.clientData}
      clone[name] = await address
      clone[`${name}_value`] = await address.full_address
      this.clientData = clone
    },
    change_checkbox(e, item){
      console.log('item', item)
      console.log('e', e)

      let clone = {...this.clientData}
      clone[item.model] = e.target.checked;
      this.clientData = clone
      if (item.hide){
        if (item.hide.length>0){
          if (e.target.checked === item.in_case){
            item.hide.forEach((ele)=>{
              this.hide_list.push(ele)
            })
          }else{
            item.hide.forEach((ele)=>{
              this.hide_list = this.hide_list.filter(item=>item!==ele)
            })
          }
        }
      }
      this.updated_call()

    },
    updated_call(){
      console.log('updated_call')
      // EventBus.$emit(this.updated_event_bus);
    }
  }
}
</script>

<style lang="scss">
.form-select{
  .ant-form-item-control{
    line-height: 30px;
  }
}
.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #d9d9d900 !important;
}
.ant-upload{
  width: 100%;
}
</style>
